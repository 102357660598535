import React, { useEffect } from 'react';

const LogoutCallback = () => {

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();

    window.location.href = 'https://portal.ai-assistant.io/';
  }, []);

  return <div>Logging out...</div>;
};

export default LogoutCallback