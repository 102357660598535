import React, { useState } from 'react';
import ErrorPage from '../../Error/ErrorPage';
import LoadingSpinner from '../../../components/LoadingSpinner';

const VirtualAssistantsDataPage = () => {
    const [error] = useState(null);
    const [loading] = useState(true);
    const columns = [
        { key: 'note', header: 'Name' },
        { key: 'type', header: 'Type' },
        { key: 'updatedByUser', header: 'Updated By' },
        { key: 'updatedDate', header: 'Updated Date' },
    ];
     
    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorPage errorCode={error.code} errorMessage={error.message} />;

    return (
        <div className="w-full h-full overflow-hidden">
            <div className="flex flex-col h-full">
                <div className="flex justify-between items-center mb-4 px-4">
                    <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Client Admin | Virtual Assistants</h1>
                </div>
                <div className="flex-grow overflow-auto">
                    <div className="bg-white dark:bg-gray-700 shadow overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                            <thead className="bg-gray-50 dark:bg-gray-800">
                                <tr>
                                    {columns.map((column) => (
                                        <th key={column.key} className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            {column.header}
                                            <input
                                                type="text"
                                                placeholder={`Search ${column.header}`}
                                                className="block w-full mt-1 p-2 border rounded text-sm dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600"
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-700 dark:divide-gray-600">
                                <tr>
                                    <td colSpan={columns.length} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                        No data available
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div className="bg-white dark:bg-gray-700 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-600 sm:px-6">
                    <div className="flex-1 flex justify-between sm:hidden">
                        <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                            Previous
                        </button>
                        <button className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                            Next
                        </button>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm text-gray-700 dark:text-gray-300">
                                Showing <span className="font-medium">0</span> to <span className="font-medium">0</span> of{' '}
                                <span className="font-medium">0</span> results
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VirtualAssistantsDataPage;