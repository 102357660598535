import axios from 'axios';

const base_url = process.env.REACT_APP_API_BASE_URL;

export const fetchClientDetails = async (clientId, token) => {
  const response = await axios.get(`${base_url}/Companies/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchSystems = async (clientId, token) => {
  const response = await axios.get(`${base_url}/Systems/getForCompany/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchBranches = async (clientId, token) => {
  const response = await axios.get(`${base_url}/Branches/selectList/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchVaData = async (clientId, token) => {
  const response = await axios.get(`${base_url}/VirtualAssistants/${clientId}/filter`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result.list;
};

export const fetchHolidays = async (clientId, token) => {
  const response = await axios.get(`${base_url}/ConversationHolidays/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchMatchedConversations = async (clientId, token) => {
  const params = new URLSearchParams({
    onlyNew: 'true', 
    sortPropertyName: 'conversation.name',
    sortAscending: 'true',
    pageSize: '30',
    pageNumber: '0',
    clientCompanyId: clientId.toString(),
  });

  const response = await axios.get(`${base_url}/Messages/incoming/extended?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result.list;
};

export const fetchOutboxMessages = async (clientId, token) => {
  const params = new URLSearchParams({
    sortAscending: 'true',
    pageSize: '30',
    pageNumber: '0',
    clientCompanyId: clientId.toString(),
    isSent: 'false',
  });

  const response = await axios.get(`${base_url}/Messages/outgoing/filter?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result.list;
};

export const fetchCustomersData = async (contactId, token) => {
  const response = await axios.get(`${base_url}/ConversationContacts/${contactId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchConversation = async (conversationId, token) => {
  const response = await axios.get(`${base_url}/Conversations/${conversationId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const updateConversation = async (conversationId, conversationData, token) => {
  const response = await axios.put(
    `${base_url}/Conversations/${conversationId}`,
    conversationData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};

export const fetchUnknownMessages = async (clientId, token) => {
  const params = new URLSearchParams({
    onlyNew: 'true',
    sortPropertyName: 'sentOnUtc',
    sortAscending: 'false',
    pageSize: '30',
    pageNumber: '0',
    clientCompanyId: clientId.toString(),
    updateTrigger: '0',
    "states[]": '0',
  });
  
  const response = await axios.get(`${base_url}/Messages/incoming/unknown/filter?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchMessageContent = async (contactId, token) => {
  const url = `${base_url}/Messages/${contactId}/filter`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchAiProcessingData = async (clientId, token) => {
  const params = new URLSearchParams({
    isSent: 'false',
    sortPropertyName: "createdDate",
    sortAscending: 'true',
    pageSize: '100',
    pageNumber: '0',
    clientCompanyId: clientId.toString(),
    "states[]": '8',
  });
  
  const response = await axios.get(`${base_url}/Messages/incoming/filter?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result.list;
};

export const updateClient = async (clientData, token) => {
  const response = await axios.put(
    `${base_url}/Companies`,
    clientData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};
