import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCog, FaTools, FaHome, FaWindowMaximize } from 'react-icons/fa';
import PropTypes from 'prop-types';

const AdminSection = ({
  icon: Icon,
  title,
  isOpen,
  toggleOpen,
  links,
  isExpanded,
  isMobile,
  hoveredItem,
  handleMouseEnter,
  handleMouseLeave,
}) => (
  <div
    className="relative mb-4"
    onMouseEnter={() => handleMouseEnter(title)}
    onMouseLeave={handleMouseLeave}
  >
    <div
      className="flex items-center py-2 px-4 hover:bg-gray-700 dark:hover:bg-gray-800 rounded cursor-pointer transition-colors duration-200"
      onClick={() => toggleOpen(prev => !prev)}
    >
      <Icon className={`${isExpanded ? 'mr-2' : 'mx-auto'} text-sm`} />
      {isExpanded && <span>{title}</span>}
      {isExpanded && (
        <svg
          className={`w-4 h-4 ml-auto transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      )}
    </div>

    {!isExpanded && !isMobile && hoveredItem === title && (
      <div className="absolute left-full top-0 mt-0 w-48 bg-gray-800 dark:bg-gray-900 text-white rounded-md overflow-hidden shadow-xl z-20">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.to}
            className="block py-2 px-4 hover:bg-gray-700 dark:hover:bg-gray-800 rounded transition-colors duration-200"
          >
            {link.text}
          </Link>
        ))}
      </div>
    )}

    {isExpanded && isOpen && (
      <div className="pl-4">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.to}
            className="block py-2 px-4 hover:bg-gray-700 dark:hover:bg-gray-800 rounded transition-colors duration-200"
          >
            {link.text}
          </Link>
        ))}
      </div>
    )}
  </div>
);

const Sidebar = ({ isExpanded, isMobile }) => {
  const [isClientAdminOpen, setIsClientAdminOpen] = useState(false);
  const [isInternalAdminOpen, setIsInternalAdminOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <aside
      className={`
        ${isExpanded ? 'w-64' : 'w-22'}
        fixed top-16 bottom-0 left-0
        bg-gray-800 dark:bg-gray-900 text-white
        transition-all duration-300 ease-in-out
        ${isMobile ? 'z-40' : 'z-30'}
        ${isMobile && !isExpanded ? '-translate-x-full' : 'translate-x-0'}
      `}
    >
      <nav className="p-4 space-y-2">
        <Link
          to="/"
          className="flex items-center p-2 rounded-lg hover:bg-gray-700 transition-colors duration-200"
        >
          <FaHome className="text-sm ms-2 mr-2" />
          {isExpanded && <span>Home</span>}
        </Link>
        <Link
          to="https://portal.ai-assistant.io/#home"
          className="flex items-center p-2 rounded-lg hover:bg-gray-700 transition-colors duration-200"
        >
          <FaWindowMaximize className="text-sm ms-2 mr-2" />
          {isExpanded && <span>Portal</span>}
        </Link>
        <AdminSection
          icon={FaUserCog}
          title="Client Admin"
          isOpen={isClientAdminOpen}
          toggleOpen={setIsClientAdminOpen}
          links={[
            { to: '/client-admin/client', text: 'Client' },
            { to: '/client-admin/systems', text: 'Systems' },
            { to: '/client-admin/branches', text: 'Branches' },
          ]}
          isExpanded={isExpanded}
          isMobile={isMobile}
          hoveredItem={hoveredItem}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
        <AdminSection
          icon={FaTools}
          title="Internal Admin"
          isOpen={isInternalAdminOpen}
          toggleOpen={setIsInternalAdminOpen}
          links={[
            { to: '/internal-admin/products', text: 'Products' },
            { to: '/internal-admin/AIML', text: 'AI/ML Models' },
            { to: '/internal-admin/providers', text: 'Providers' },
          ]}
          isExpanded={isExpanded}
          isMobile={isMobile}
          hoveredItem={hoveredItem}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      </nav>
    </aside>
  );
};

Sidebar.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired
};

AdminSection.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  hoveredItem: PropTypes.string,
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired
};

export default Sidebar;
