import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { v4 as uuidv4 } from 'uuid';

const validateField = (name, value) => {
  switch (name) {
    case 'name':
      return value.trim() === '' ? 'Name is required' : '';
    case 'description':
      return value.trim() === '' ? 'Description is required' : '';
    case 'batchNotify':
      return /\S+@\S+\.\S+/.test(value) ? '' : 'Invalid email format';
    default:
      return '';
  }
};

const AddSystemPage = () => {
  const [system, setSystem] = useState({
    companyId: '',
    name: '',
    description: '',
    guid: '',
    batchNotify: '',
    processMotcheck: false,
    foreignKey: '0',
    type: 1,
    ipAddress: '',
    databaseUserName: '',
    properties: '{}',
    state: 0
  });
  
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCompanyList();
  }, []);

  const fetchCompanyList = async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    
    try {
      const token = grabToken();
      const response = await axios.get(`${apiBaseUrl}/Companies/filter`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: {
          pageSize: 100
        }
      });
      
      if (response.data && response.data.result && Array.isArray(response.data.result.list)) {
        setCompanies(response.data.result.list);
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value;
    
    setSystem(prev => ({
      ...prev,
      [name]: newValue
    }));

    const fieldError = validateField(name, newValue);
    setErrors(prev => ({
      ...prev,
      [name]: fieldError
    }));
  };

  const handleCompanyChange = (e) => {
    const selectedCompany = companies.find(company => company.id === e.target.value);
    setSystem(prev => ({
      ...prev,
      companyId: selectedCompany.id,
      company: {
        id: selectedCompany.id,
        name: selectedCompany.name
      }
    }));
  };

  const handleGenerateGuid = () => {
    setSystem(prev => ({
      ...prev,
      guid: uuidv4()
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = Object.keys(system).reduce((acc, key) => {
      const fieldError = validateField(key, system[key]);
      if (fieldError) {
        acc[key] = fieldError;
      }
      return acc;
    }, {});
  
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
  
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = grabToken();
      
      const requestBody = {
        companyId: system.companyId,
        name: system.name,
        description: system.description,
        guid: system.guid,
        batchNotify: system.batchNotify,
        processMotcheck: system.processMotcheck,
        foreignKey: system.foreignKey.toString(),
        type: parseInt(system.type),
        ipAddress: system.ipAddress,
        databaseUserName: system.databaseUserName,
        properties: system.properties,
        state: parseInt(system.state),
        company: system.company
      };
      
      const response = await axios.post(
        `${apiBaseUrl}/Systems`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Add System Response:', response);
      console.log('Add System Data:', response.data);
  
      if (response.status === 200) {
        navigate('/client-admin/systems');
      } else {
        throw new Error('Failed to add system');
      }
    } catch (error) {
      console.error('Error adding system:', error);
      setError(error.response 
        ? `Error adding system: ${error.response.status} - ${JSON.stringify(error.response.data)}` 
        : error.message
      );
    }
  };

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate('/client-admin/systems');
    }
  };

  const inputClass = `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode 
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white' 
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`;

  const errorClass = 'text-red-500 text-sm mt-1';

  const renderField = (label, name, type = 'text') => {
    if (name === 'companyId') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <select
              id={name}
              name={name}
              value={system.companyId || ''}
              onChange={handleCompanyChange}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            >
              <option value="">Select Company</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
            {errors[name] && <span className={errorClass}>{errors[name]}</span>}
          </div>
        </div>
      );
    } else if (name === 'guid') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2 flex items-center">
            <input
              type="text"
              id={name}
              name={name}
              value={system[name] || ''}
              onChange={handleInputChange}
              placeholder="Generate a guid"
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''} bg-gray-100 flex-grow`}
            />
            <button
              type="button"
              onClick={handleGenerateGuid}
              className={`ml-2 px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
              }`}
            >
              Generate
            </button>
          </div>
          {errors[name] && <span className={errorClass}>{errors[name]}</span>}
        </div>
      );
    } else if (type === 'checkbox') {
      return (
        <div className="flex items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <input
            type="checkbox"
            id={name}
            name={name}
            checked={system[name] || false}
            onChange={handleInputChange}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor={name} className="ml-2 block text-sm text-gray-900 dark:text-gray-300">
            {label}
          </label>
        </div>
      );
    }
    
    return (
      <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
        <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
        </label>
        <div className="col-span-2">
          <input
            type={type}
            id={name}
            name={name}
            value={system[name] || ''}
            onChange={handleInputChange}
            className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
          />
          {errors[name] && <span className={errorClass}>{errors[name]}</span>}
        </div>
      </div>
    );
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            New System Information
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6 space-y-4">
          {renderField('Company', 'companyId')}
          {renderField('Name', 'name')}
          {renderField('Description', 'description')}
          {renderField('GUID', 'guid')}
          {renderField('Batch Notify Email', 'batchNotify', 'email')}
          {renderField('Process MOT Check', 'processMotcheck', 'checkbox')}
          {renderField('Foreign Key', 'foreignKey')}
          {renderField('Type', 'type', 'number')}
          {renderField('IP Address', 'ipAddress')}
          {renderField('Database Username', 'databaseUserName')}
          {/* {renderField('State', 'state', 'number')} */}
        </div>
      </div>
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden mt-6">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            Properties (JSON)
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <JSONInput
            id="properties-json-editor"
            placeholder={JSON.parse(system.properties)}
            locale={locale}
            height="300px"
            width="100%"
            onChange={(data) => setSystem(prev => ({ ...prev, properties: JSON.stringify(data.jsObject) }))}
            theme={isDarkMode ? "dark_vscode_tribute" : "light_mitsuketa_tribute"}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="submit"
          className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
            isDarkMode
              ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
              : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
          }`}
        >
          Add System
        </button>
      </div>
    </form>
  );

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Add New System</h1>
          <button
            type="button"
            onClick={handleBackClick}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back
          </button>
        </div>
        
        {renderForm()}
      </div>
    </div>
  );
};

export default AddSystemPage;