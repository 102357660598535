import React from 'react';
import PropTypes from 'prop-types';

const CustomerInfoTable = ({ customer, vehicle, branch, conversation }) => {
  const tableClass = `w-full text-left border-collapse`;
  const thClass = `px-4 py-2 bg-gray-200 dark:bg-gray-800 font-semibold text-sm`;
  const tdClass = `px-4 py-2 border-t dark:border-gray-600 text-sm`;

  return (
    <div className={`bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden`}>
      <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
          Customer Information
        </h3>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <table className={tableClass}>
          <thead>
            <tr>
              <th className={thClass} colSpan={2}>Customer Details</th>
              <th className={thClass} colSpan={2}>Vehicle Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={tdClass}><strong>Name:</strong></td>
              <td className={tdClass}>{customer?.title} {customer?.firstName} {customer?.lastName}</td>
              <td className={tdClass}><strong>Registration:</strong></td>
              <td className={tdClass}>{vehicle?.regNumber}</td>
            </tr>
            <tr>
              <td className={tdClass}><strong>Email:</strong></td>
              <td className={tdClass}>{customer?.emailHome}</td>
              <td className={tdClass}><strong>VIN:</strong></td>
              <td className={tdClass}>{vehicle?.vin}</td>
            </tr>
            <tr>
              <td className={tdClass}><strong>ID:</strong></td>
              <td className={tdClass}>{customer?.id}</td>
              <td className={tdClass}><strong>Make:</strong></td>
              <td className={tdClass}>{vehicle?.make}</td>
            </tr>
            <tr>
              <td className={tdClass}><strong>Foreign Key:</strong></td>
              <td className={tdClass}>{customer?.foreignKey}</td>
              <td className={tdClass}><strong>Model:</strong></td>
              <td className={tdClass}>{vehicle?.model}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th className={thClass} colSpan={2}>Branch Details</th>
              <th className={thClass} colSpan={2}>Conversation Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={tdClass}><strong>Name:</strong></td>
              <td className={tdClass}>{branch?.name}</td>
              <td className={tdClass}><strong>ID:</strong></td>
              <td className={tdClass}>{conversation?.id}</td>
            </tr>
            <tr>
              <td className={tdClass}><strong>Address:</strong></td>
              <td className={tdClass}>{branch?.address1}, {branch?.town}, {branch?.county}, {branch?.zipPostCode}</td>
              <td className={tdClass}><strong>Name:</strong></td>
              <td className={tdClass}>{conversation?.name}</td>
            </tr>
            <tr>
              <td className={tdClass}><strong>Phone:</strong></td>
              <td className={tdClass}>{branch?.tel}</td>
              <td className={tdClass}><strong>Product:</strong></td>
              <td className={tdClass}>{conversation?.conversationProduct}</td>
            </tr>
            <tr>
              <td className={tdClass}><strong>Country:</strong></td>
              <td className={tdClass}>{branch?.country}</td>
              <td className={tdClass}></td>
              <td className={tdClass}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

CustomerInfoTable.propTypes = {
  customer: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
  branch: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};


export default CustomerInfoTable;