import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useNavigate, Link } from 'react-router-dom';
import ErrorPage from '../../Error/ErrorPage';

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-full">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 dark:border-gray-100"></div>
  </div>
);

const SystemDataPage = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    id: '',
    clientName: '',
    systemName: '',
    foreignKey: '',
    motCheck: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const token = grabToken();
        const response = await axios.get(`${apiBaseUrl}/Systems`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
          params: {
            pageSize: 200
          }
        });
        if (response.data && response.data.result && Array.isArray(response.data.result.list)) {
          setData(response.data.result.list);
        } else {
          setError('Received data is not in the expected format');
        }
      } catch (error) {
        setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (property, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [property]: value
    }));
    setCurrentPage(1);
  };

  const filteredData = data.filter(item =>
    item.id.toString().toLowerCase().includes(filters.id.toLowerCase()) &&
    item.company.name.toLowerCase().includes(filters.clientName.toLowerCase()) &&
    item.name.toLowerCase().includes(filters.systemName.toLowerCase()) &&
    item.foreignKey.toLowerCase().includes(filters.foreignKey.toLowerCase()) &&
    (filters.motCheck === '' || item.processMotcheck.toString().toLowerCase() === filters.motCheck.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowClick = (systemId) => {
    navigate(`/client-admin/systems/${systemId}`);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage errorCode={error.code} errorMessage={error.message} />;

  return (
    <div className="w-full h-full overflow-hidden">
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center mb-4 px-4">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Client Admin | Systems</h1>
          <Link
            to="/client-admin/systems/add"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
          >
            Add New System
          </Link>
        </div>
        <div className="flex-grow overflow-auto">
          <div className="bg-white dark:bg-gray-700 shadow overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  {['ID', 'Client', 'System Name', 'Foreign Key', 'MOT check'].map((header) => (
                    <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      {header}
                      <input
                        type="text"
                        placeholder={`Search ${header}`}
                        className="block w-full mt-1 p-2 border rounded text-sm dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600"
                        value={filters[header === 'Client' ? 'clientName' : header === 'System Name' ? 'systemName' : header === 'Foreign Key' ? 'foreignKey' : header === 'MOT check' ? 'motCheck' : header.toLowerCase().replace(' ', '')]}
                        onChange={(e) => handleFilterChange(header === 'Client' ? 'clientName' : header === 'System Name' ? 'systemName' : header === 'Foreign Key' ? 'foreignKey' : header === 'MOT check' ? 'motCheck' : header.toLowerCase().replace(' ', ''), e.target.value)}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-700 dark:divide-gray-600">
                {currentItems.length === 0 ? (
                  <tr className='h-[53px]'>
                    <td colSpan={5} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                      No data available
                    </td>
                  </tr>
                ) : (
                  currentItems.map((item) => (
                    <tr
                      key={item.id}
                      className="h-[53px] cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
                      onClick={() => handleRowClick(item.id)}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.company.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.foreignKey}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                        {item.processMotcheck ? 'Yes' : 'No'}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-700 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-600 sm:px-6">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastItem >= filteredData.length}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700 dark:text-gray-300">
                Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to <span className="font-medium">{Math.min(indexOfLastItem, filteredData.length)}</span> of{' '}
                <span className="font-medium">{filteredData.length}</span> results
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => paginate(i + 1)}
                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                      currentPage === i + 1
                        ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                    }`}
                  >
                    {i + 1}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemDataPage;
