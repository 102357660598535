import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import ErrorPage from '../../Error/ErrorPage';

const VirtualAssistantDetailPage = () => {
  const [assistant, setAssistant] = useState({
    id: '',
    name: '',
    phoneNumber: '',
    timeZone: '',
    note: '',
    isEmailEnabled: false,
    isSmsEnabled: false,
    companyId: '',
    emailProviderId: '',
    state: 0,
    emailSettings: {
      email: '',
      signature: '',
      outOfOffice: '',
      outOfOfficeEnabled: false,
      emailsIntervalInSeconds: 0,
      dailyEmailsLimit: 0,
    },
    company: { id: '', name: '' },
    emailProvider: { id: '', name: '' },
  });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('details');
  const { id } = useParams();
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (id) {
      fetchAssistantDetails(id);
    }
  }, [id]);

  const fetchAssistantDetails = async (assistantId) => {
    setLoading(true);
    setError(null);
    try {
      const token = grabToken();
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${apiBaseUrl}/VirtualAssistants/${assistantId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setAssistant(response.data.result);
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return value.trim() === '' ? 'Name is required' : '';
      case 'phoneNumber':
        return value.trim() === '' ? 'Phone number is required' : '';
      case 'emailSettings.email':
        return value.trim() === '' ? 'Email is required' : '';
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value;

    setAssistant(prev => {
      if (name.includes('.')) {
        const [objName, key] = name.split('.');
        return {
          ...prev,
          [objName]: {
            ...prev[objName],
            [key]: newValue
          }
        };
      }
      return { ...prev, [name]: newValue };
    });

    const fieldError = validateField(name, newValue);
    setErrors(prev => ({
      ...prev,
      [name]: fieldError
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/client-admin/client');
    }
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    fetchAssistantDetails(assistant.id);
  };

  const inputClass = `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode 
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white' 
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`;

  const errorClass = 'text-red-500 text-sm mt-1';

  const tabClass = (tabName) => `
    px-4 py-2 font-medium text-sm rounded-t-lg
    ${activeTab === tabName
      ? isDarkMode
        ? 'bg-gray-700 text-white'
        : 'bg-white text-blue-600'
      : isDarkMode
      ? 'bg-gray-600 text-gray-300 hover:bg-gray-500'
      : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
    }
  `;

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <ErrorPage errorCode={error.code} errorMessage={error.message} />;

  const renderField = (label, name, type = 'text', options = []) => {
    const value = name.includes('.') 
      ? name.split('.').reduce((obj, key) => obj[key], assistant)
      : assistant[name];

    return (
      <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
        <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
        </label>
        <div className="col-span-2">
          {type === 'select' ? (
            <select
              id={name}
              name={name}
              value={value || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            >
              <option value="">Select {label}</option>
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : type === 'checkbox' ? (
            <input
              type="checkbox"
              id={name}
              name={name}
              checked={value || false}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`form-checkbox h-5 w-5 ${
                isDarkMode ? 'text-blue-600 bg-gray-700' : 'text-indigo-600'
              }`}
            />
          ) : (
            <input
              type={type}
              id={name}
              name={name}
              value={value || ''}
              onChange={handleInputChange}
              disabled={!isEditing || name === 'company.name' || name === 'emailProvider.name'}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            />
          )}
          {errors[name] && <span className={errorClass}>{errors[name]}</span>}
        </div>
      </div>
    );
  };

  const renderDetailsTab = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            Virtual Assistant Information
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6 space-y-4">
          {renderField('Company', 'company.name')}
          {renderField('Name', 'name')}
          {renderField('Phone Number', 'phoneNumber')}
          {/* {renderField('Time Zone', 'timeZone')} */}
          {renderField('Note', 'note')}
          {renderField('Email Enabled', 'isEmailEnabled', 'checkbox')}
          {renderField('SMS Enabled', 'isSmsEnabled', 'checkbox')}
          {renderField('Email Provider', 'emailProvider.name')}
          {renderField('Email', 'emailSettings.email')}
          {renderField('Emails Interval (seconds)', 'emailSettings.emailsIntervalInSeconds', 'number')}
          {renderField('Daily Emails Limit', 'emailSettings.dailyEmailsLimit', 'number')}
          {renderField('State', 'state', 'number')}
        </div>
      </div>
      <div className="flex justify-between">
        {isEditing ? (
          <>
            <button
              type="submit"
              className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
              }`}
            >
              Save Changes
            </button>
            <button
              type="button"
              onClick={handleCancelEdit}
              className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                  : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
              }`}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={handleEditClick}
            className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-white'
                : 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400 text-white'
            }`}
          >
            Edit Assistant
          </button>
        )}
      </div>
    </form>
  );

  const renderLogsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Virtual Assistant Logs
        </h3>
        {assistant.lastEvent ? (
          <div>
            <p><strong>Last Event:</strong> {assistant.lastEvent.data.message}</p>
            <p><strong>Additional Message:</strong> {assistant.lastEvent.data.additionalMessage}</p>
            <p><strong>Created Date:</strong> {new Date(assistant.lastEvent.createdDate).toLocaleString()}</p>
          </div>
        ) : (
          <p className="text-sm text-gray-600 dark:text-gray-300">No logs available</p>
        )}
      </div>
    </div>
  );

  const renderScheduleTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Working Schedule
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Schedule
        </p>
      </div>
    </div>
  );

  const renderEventsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Events
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Events
        </p>
      </div>
    </div>
  );

  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Virtual Assistant Details</h1>
          <button
            type="button"
            onClick={handleBackClick}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back
          </button>
        </div>
        
        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                className={tabClass('details')}
                onClick={() => setActiveTab('details')}
              >
                Details
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('schedule')}
                onClick={() => setActiveTab('schedule')}
              >
                Working Schedule
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('events')}
                onClick={() => setActiveTab('events')}
              >
                Events
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('logs')}
                onClick={() => setActiveTab('logs')}
              >
                Logs
              </button>
            </li>
          </ul>
        </div>
        
        {activeTab === 'details' ? renderDetailsTab() 
         : activeTab === 'schedule' ? renderScheduleTab()
         : activeTab === 'events' ? renderEventsTab()
         : renderLogsTab()}
      </div>
    </div>
  );
};

export default VirtualAssistantDetailPage;