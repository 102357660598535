import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "./ErrorPage.css";
import { useTheme } from "../../contexts/ThemeContext";

const ErrorPage = ({ errorCode, errorMessage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useTheme();

  const { state } = location;
  const dynamicErrorCode = state?.errorCode || errorCode;
  const dynamicErrorMessage = state?.errorMessage || errorMessage;

  const handleBackToHome = () => {
    console.log("Navigating back to home");
    navigate("/");
  };

  const getErrorMessage = () => {
    if (dynamicErrorMessage) return dynamicErrorMessage;
    switch (dynamicErrorCode) {
      case 404:
        return "The page you're looking for doesn't exist.";
      case 500:
        return "Internal server error. Please try again later.";
      default:
        return "An unexpected error has occurred.";
    }
  };

  const containerStyle = isDarkMode
    ? "bg-gray-800 text-white"
    : "bg-white text-gray-800";

  const buttonStyle = isDarkMode
    ? "bg-gray-700 text-white hover:bg-gray-600"
    : "bg-gray-300 text-gray-800 hover:bg-gray-400";

  return (
    <div className={`error-container ${containerStyle}`} role="alert">
      <h1 className="error-title">Oops! Something went wrong.</h1>
      <p className="error-message">{getErrorMessage()}</p>
      {dynamicErrorCode && <p>Error code: {dynamicErrorCode}</p>}
      <button onClick={handleBackToHome} className={`error-link ${buttonStyle}`}>
        Go back to homepage
      </button>
      <p>
        If this error persists, please <a href="/contact">contact our support team</a>.
      </p>
    </div>
  );
};

ErrorPage.propTypes = {
  errorCode: PropTypes.number,
  errorMessage: PropTypes.string,
};

export default ErrorPage;
