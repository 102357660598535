import React from 'react';

const Dashboard = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-start bg-gray-100 dark:bg-gray-800 px-4 py-8 md:px-8">
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg p-6 sm:p-8 md:p-12 w-full max-w-4xl mt-4">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-gray-900 dark:text-white text-center">
          Welcome to Vision2
        </h1>
        <p className="text-base sm:text-lg md:text-xl text-gray-700 dark:text-gray-300 text-center">
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
