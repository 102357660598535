import React from 'react';
import PropTypes from 'prop-types';


const VaTab = ({ vaData, isDarkMode, navigate }) => {
  return (
    <div className="flex flex-col">
      <div className="w-full">
        <div className={`bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden`}>
          <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
            <h3 className={`text-lg leading-6 font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Virtual Assistants
            </h3>
          </div>
          <div className="px-4 py-5 sm:p-6 overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
              <thead className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <tr>
                  <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>Name</th>
                  <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>Email</th>
                  <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>Token Expiration</th>
                  <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>Last Updated</th>
                  <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>Actions</th>
                </tr>
              </thead>
              <tbody className={`${isDarkMode ? 'bg-gray-700' : 'bg-white'} divide-y divide-gray-200 dark:divide-gray-600`}>
                {vaData.map((va) => (
                  <tr key={va.id}>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{va.name}</td>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>{va.emailSettings?.email}</td>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>{new Date(va.graphApiToken?.expireOnUtc).toLocaleString()}</td>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>{new Date(va.updatedDate).toLocaleString()}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => navigate(`/client-admin/virtual-assistants/${va.id}`)}
                        className={`px-2 py-1 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                          isDarkMode
                            ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                            : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
                        }`}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

VaTab.propTypes = {
  vaData: PropTypes.array.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default VaTab;