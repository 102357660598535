export const grabToken = () => {
  try {
    const accessTokenObj = window.localStorage.getItem('app.Authorization'); 
    if (accessTokenObj) {
      return accessTokenObj;
    } else {
      throw new Error('Token not found in the response');
    }
  } catch (error) {
    console.error('Error fetching token:', error.response ? error.response.data : error.message);
    throw error;
  }
};
