import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import MonacoEditor from 'react-monaco-editor';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { notification } from 'antd';
import ErrorPage from '../../../pages/Error/ErrorPage';
import LoadingSpinner from '../../../components/LoadingSpinner';

const SystemDetailsPage = () => {
  const [system, setSystem] = useState({
    databasePassword: '',
    id: '',
    companyId: '',
    name: '',
    description: '',
    guid: '',
    batchNotify: '',
    processMotcheck: false,
    foreignKey: '',
    type: '',
    ipAddress: '',
    databaseUserName: '',
    properties: '',
    state: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('details');
  const [logs, setLogs] = useState([]);
  const [jsonData, setJsonData] = useState(null);
  const [, setJsonError] = useState(null);
  const [, setIsJsonValid] = useState(false); 
  const [showPassword, setShowPassword] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [editedJsonString, setEditedJsonString] = useState('');
  const [savedJsonString, setSavedJsonString] = useState('');
  const [isJsonValidated, setIsJsonValidated] = useState(false);
  const [editorHeight, setEditorHeight] = useState('720px'); 

  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const detailsRef = useRef(null);
  const jsonDataRef = useRef(jsonData);
  const editorContainerRef = useRef(null);
  const location = useLocation();


  useEffect(() => {
    if (id) {
      fetchSystemDetails(id);
    }
  }, [id]);

  useEffect(() => {
    const updateEditorHeight = () => {
      if (detailsRef.current && editorContainerRef.current) {
        const detailsHeight = detailsRef.current.offsetHeight;
        setEditorHeight(`${detailsHeight}px`);
      }
    };

    updateEditorHeight();
    window.addEventListener('resize', updateEditorHeight);

    return () => {
      window.removeEventListener('resize', updateEditorHeight);
    };
  }, []);

  useEffect(() => {
    jsonDataRef.current = jsonData;
  }, [jsonData]);

 useEffect(() => {
  if (system.properties) {
    try {
      const jsonProperties = JSON.stringify(JSON.parse(system.properties.replace(/'/g, '"')), null, 2);
      setEditedJsonString(jsonProperties);
      setSavedJsonString(jsonProperties);
    } catch (error) {
      console.error('Error parsing system properties JSON:', error);
      setEditedJsonString('Invalid JSON format in properties.');
    }
  }
}, [system.properties]);
 
  
  useEffect(() => {
    console.log('isEditing state changed:', isEditing);
  }, [isEditing]);

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return value.trim() === '' ? 'Name is required' : '';
      case 'ipAddress':
        return value.trim() === '' ? 'IP Address is required' : '';
      case 'databaseUserName':
        return value.trim() === '' ? 'Database Username is required' : '';
      case 'databasePassword':
        return value.trim() === '' ? 'Database Password is required' : '';
      default:
        return '';
    }
  };

  const handleJsonChange = (value) => {
    setEditedJsonString(value);
    setIsJsonValidated(false);
  };

  const handleValidateClick = () => {
    try {
      JSON.parse(editedJsonString); // Try to parse JSON
      setJsonError(null);
      setIsJsonValid(true); // Update state to valid
      setIsJsonValidated(true); // Mark JSON as validated
      notification.success({
        message: 'JSON is valid',
      });
    } catch (error) {
      setJsonError(`Invalid JSON: ${error.message}`);
      setIsJsonValid(false); // Update state to invalid
      setIsJsonValidated(false);
      notification.error({
        message: 'Invalid JSON',
        description: error.message,
      });
    }
  };

  const handleSaveJson = () => {
    if (!isJsonValidated) {
      notification.warning({
        message: 'Please validate the JSON before saving',
      });
      return;
    }
    try {
      const parsedJson = JSON.parse(editedJsonString);
      setSavedJsonString(editedJsonString);
      setJsonData(parsedJson);
      setJsonError(null);
      setIsJsonValid(true);
      notification.success({
        message: 'JSON changes saved',
      });
    } catch (error) {
      console.error('Error saving JSON:', error);
      setJsonError('Invalid JSON: ' + error.message);
      setIsJsonValid(false);
      notification.error({
        message: 'Failed to save JSON',
        description: 'Please check the JSON format and try again.',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setSystem(prev => ({
      ...prev,
      [name]: newValue
    }));

    const fieldError = validateField(name, newValue);
    setErrors(prev => ({
      ...prev,
      [name]: fieldError
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted. Current states:');
    console.log('isEditing:', isEditing);
    console.log('editedJsonString:', editedJsonString);
    console.log('system.properties:', system.properties);
    setLoading(true);

    const formErrors = Object.keys(system).reduce((acc, key) => {
      const fieldError = validateField(key, system[key]);
      if (fieldError) {
        acc[key] = fieldError;
      }
      return acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoading(false);
      return;
    }

    try {
      const token = grabToken();
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const payload = {
        id: parseInt(system.id, 10),
        name: system.name || '',
        type: system.type || 0,
        companyId: system.companyId || '',
        guid: system.guid || '',
        description: system.description || '',
        ipAddress: system.ipAddress || '',
        batchNotify: system.batchNotify || '',
        foreignKey: system.foreignKey || '',
        properties: savedJsonString,
        databaseUserName: system.databaseUserName || '',
        databasePassword: system.databasePassword,
        processMotcheck: system.processMotcheck || false,
        state: system.state || 0,
      };

      console.log('Request payload:', JSON.stringify(payload, null, 2));
      console.log('Sending savedJsonString:', savedJsonString);
      const response = await axios.put(
        `${apiBaseUrl}/Systems/${system.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Response:', response.data);

      setLoading(false);
      setIsEditing(false);
      notification.success({
        message: 'System was updated',
      });
      fetchSystemDetails(system.id);
    } catch (error) {
      setLoading(false);
      console.error('Error updating system:', error);

      let errorMessage = 'System update failed';
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);

        if (error.response.data.errors && error.response.data.errors.length > 0) {
          errorMessage = error.response.data.errors[0];
        } else if (typeof error.response.data === 'object' && error.response.data !== null) {
          errorMessage += `: ${JSON.stringify(error.response.data)}`;
        } else if (typeof error.response.data === 'string') {
          errorMessage += `: ${error.response.data}`;
        } else {
          errorMessage += `: Status ${error.response.status}`;
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
        errorMessage = 'No response received from server';
      } else {
        console.error('Error message:', error.message);
        errorMessage = error.message;
      }

      setError(errorMessage);
      notification.error({
        message: 'System update failed',
        description: errorMessage,
      });
    }
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    fetchSystemDetails(system.id);
  };

  // API calls
  const fetchSystemDetails = async (systemId) => {
    setLoading(true);
    setError(null);
    try {
      const token = grabToken();
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${apiBaseUrl}/Systems/getWithCredentials/${systemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setSystem(prevSystem => ({
        ...prevSystem,
        ...response.data.result
      }));
      const properties = response.data.result.properties.replace(/'/g, '"');
      setJsonData(JSON.parse(properties));
      jsonDataRef.current = JSON.parse(properties);

      setLogs([
        { timestamp: new Date().toISOString(), message: 'System details fetched' },
        { timestamp: new Date(Date.now() - 86400000).toISOString(), message: 'System last updated' },
      ]);

      if (response.data.result.companyId) {
        fetchCompanyDetails(response.data.result.companyId);
      }

    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyDetails = async (companyId) => {
    try {
      const token = grabToken();
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${apiBaseUrl}/Companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setCompanyName(response.data.result.name);
    } catch (error) {
      console.error('Error fetching company details:', error);
      setCompanyName('Unable to fetch company name');
    }
  };

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/client-admin/systems');
    }
  };

  const inputClass = `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode 
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white' 
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`;

  const errorClass = 'text-red-500 text-sm mt-1';

  const tabClass = (tabName) => `
    px-4 py-2 font-medium text-sm rounded-t-lg
    ${activeTab === tabName
      ? isDarkMode
        ? 'bg-gray-700 text-white'
        : 'bg-white text-blue-600'
      : isDarkMode
      ? 'bg-gray-600 text-gray-300 hover:bg-gray-500'
      : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
    }
  `;

  const renderField = (label, name, type = 'text', step) => {
    if (name === 'databasePassword') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2 relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id={name}
              name={name}
              value={system[name] || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`${inputClass} pr-10 ${errors[name] ? 'border-red-500' : ''}`}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-400" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" />
              )}
            </button>
            {errors[name] && <span className={errorClass}>{errors[name]}</span>}
          </div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
        <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
        </label>
        <div className="col-span-2">
          <input
            type={type}
            id={name}
            name={name}
            value={system[name] || ''}
            onChange={handleInputChange}
            disabled={!isEditing}
            step={step}
            className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
          />
          {errors[name] && <span className={errorClass}>{errors[name]}</span>}
        </div>
      </div>
    );
  };

  const renderDetailsTab = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div ref={detailsRef} className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6 space-y-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
            System Details
          </h3>

          <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Company Name
            </label>
            <div className="col-span-2">
              <input
                type="text"
                value={companyName || 'Loading...'}
                disabled
                className={`${inputClass} bg-gray-100 dark:bg-gray-600`}
              />
            </div>
          </div>

          {renderField('Name', 'name')}
          {renderField('Description', 'description')}
          {renderField('IP Address', 'ipAddress')}
          {renderField('Database Username', 'databaseUserName')}
          {renderField('Database Password', 'databasePassword', 'password')}
          {renderField('Foreign Key', 'foreignKey')}
          {renderField('Batch Notify Email', 'batchNotify')}
        </div>
      </div>
      <div className="flex justify-between">
        {isEditing ? (
          <>
            <button
              type="submit"
              className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
              }`}
            >
              Save Changes
            </button>
            <button
              type="button"
              onClick={handleCancelEdit}
              className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                  : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
              }`}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={handleEditClick}
            className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-white'
                : 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400 text-white'
            }`}
          >
            Edit System
          </button>
        )}
      </div>
    </form>
  );

  const renderJsonEditor = () => {
    return (
      <div
        ref={editorContainerRef}
        className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden flex flex-col"
        style={{ height: editorHeight }}
      >
        <div className="flex-grow">
        <MonacoEditor
          width="100%"
          height="100%"
          language="json"
          theme={isDarkMode ? 'vs-dark' : 'vs-light'}
          value={editedJsonString}
          onChange={handleJsonChange} 
          options={{
            minimap: { enabled: false },
            automaticLayout: true,
            scrollBeyondLastLine: false,
            fontSize: 14,
            readOnly: !isEditing,
            scrollbar: {
              vertical: 'visible',
              horizontal: 'visible',
            },
          }}
        />
        </div>
        <div className="p-2 bg-gray-50 dark:bg-gray-600 flex justify-between">
          <button
            onClick={handleSaveJson}
            disabled={!isEditing || !isJsonValidated}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isEditing && isJsonValidated
                ? isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Save JSON Changes
          </button>
          <button
            onClick={handleValidateClick}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white'
                : 'bg-green-500 hover:bg-green-600 focus:ring-green-400 text-white'
            }`}
          >
            Validate JSON
          </button>
        </div>
      </div>
    );
  };

  const renderLogsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden p-4">
      <h2 className="text-xl font-semibold mb-4">System Logs</h2>
      {logs.map((log, index) => (
        <div key={index} className="mb-2">
          <span className="font-medium">{new Date(log.timestamp).toLocaleString()}: </span>
          <span>{log.message}</span>
        </div>
      ))}
    </div>
  );

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage errorCode={error.code} errorMessage={error.message} />;

  return (
    <div className={`flex flex-col h-screen ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">System Details</h1>
          <button
            type="button"
            onClick={handleBackClick}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back
          </button>
        </div>

        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                className={tabClass('details')}
                onClick={() => setActiveTab('details')}
              >
                Details
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('logs')}
                onClick={() => setActiveTab('logs')}
              >
                Logs
              </button>
            </li>
          </ul>
        </div>

        {activeTab === 'details' ? (
          <div className="flex space-x-4">
            <div className="w-1/2" ref={detailsRef}>{renderDetailsTab()}</div>
            <div className="w-1/2">{renderJsonEditor()}</div>
          </div>
        ) : (
          renderLogsTab()
        )}
      </div>
    </div>
  );
};

export default SystemDetailsPage;
