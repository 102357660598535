// src/reducers/userReducer.js
import { createSlice } from '@reduxjs/toolkit';

// Create a slice for user authentication
const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false,
    user: null,
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

// Export the actions and reducer
export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
