// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';

const rootReducer = {
  user: userReducer,
};


const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, 
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
