import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import { X, Plus } from 'lucide-react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const validateField = (name, value) => {
  switch (name) {
    case 'company.id':
      return value ? '' : 'Company is required';
    case 'name':
      return value && value.trim() !== '' ? '' : 'Name is required';
    case 'address1':
      return value && value.trim() !== '' ? '' : 'Address is required';
    case 'town':
      return value && value.trim() !== '' ? '' : 'Town is required';
    case 'county':
      return value && value.trim() !== '' ? '' : 'County is required';
    case 'zipPostCode':
      return value && value.trim() !== '' ? '' : 'Zip/Post Code is required';
    case 'tel':
      return value && value.replace(/\D/g, '').length >= 6 ? '' : 'Valid telephone number is required';
    case 'country':
      return value && value.trim() !== '' ? '' : 'Country is required';
    default:
      return '';
  }
};

const AddBranchPage = () => {
  const [branch, setBranch] = useState({
    company: {
      id: '',
      name: ''
    },
    distributionEmails: [],
    reference: '',
    codesArrayJson: '[]',
    address1: '',
    address2: '',
    county: '',
    country: '',
    tel: '',
    town: '',
    zipPostCode: '',
    accountcode: '',
    codes: [],
    state: 0,
    id: '',
    name: ''
  });
  
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [newCode, setNewCode] = useState('');
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchClientList();
  }, []);

  const fetchClientList = async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    
    try {
      const token = grabToken();
      const response = await axios.get(`${apiBaseUrl}/Companies/filter`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: {
          pageSize: 100
        }
      });
      
      if (response.data && response.data.result && Array.isArray(response.data.result.list)) {
        setClients(response.data.result.list);
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyChange = (e) => {
    const selectedCompany = clients.find(client => client.id === e.target.value);
    setBranch(prev => ({
      ...prev,
      company: {
        id: selectedCompany.id,
        name: selectedCompany.name
      }
    }));
  };

  const handleGenerateAccountcode = () => {
    setBranch(prev => ({
      ...prev,
      accountcode: uuidv4()
    }));
  };

  const handleGenerateGuid = () => {
    setBranch(prev => ({
      ...prev,
      id: uuidv4()
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value;
    
    setBranch(prev => ({
      ...prev,
      [name]: newValue
    }));
  
    const fieldError = validateField(name, newValue);
    setErrors(prev => ({
      ...prev,
      [name]: fieldError
    }));
  };

  const handleDeleteCode = (indexToDelete) => {
    setBranch(prevBranch => ({
      ...prevBranch,
      codes: prevBranch.codes.filter((_, index) => index !== indexToDelete)
    }));
  };

  const handleAddCode = (e) => {
    e.preventDefault(); 
    if (newCode.trim() !== '') {
      setBranch(prevBranch => ({
        ...prevBranch,
        codes: [...prevBranch.codes, newCode.trim()]
      }));
      setNewCode('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = Object.keys(branch).reduce((acc, key) => {
      const fieldError = validateField(key, branch[key]);
      if (fieldError) {
        acc[key] = fieldError;
      }
      return acc;
    }, {});
  
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    const requiredFields = ['company.id', 'name', 'address1', 'town', 'county', 'zipPostCode', 'tel', 'country'];
    const newErrors = {};
    
    requiredFields.forEach(field => {
      const value = field.includes('.') ? branch[field.split('.')[0]][field.split('.')[1]] : branch[field];
      const error = validateField(field, value);
      if (error) {
        newErrors[field] = error;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = grabToken();
      
      const requestBody = {
        company: branch.company,
        distributionEmails: branch.distributionEmails,
        reference: branch.reference,
        codesArrayJson: JSON.stringify(branch.codes),
        address1: branch.address1,
        address2: branch.address2,
        county: branch.county,
        country: branch.country.toLowerCase(),
        tel: branch.tel,
        town: branch.town,
        zipPostCode: branch.zipPostCode,
        accountcode: branch.accountcode,
        state: branch.state || '0', 
        name: branch.name
      };
      
      const response = await axios.post(
        `${apiBaseUrl}/Branches`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        navigate('/client-admin/branches');
      } else {
        throw new Error('Failed to add branch');
      }
    } catch (error) {
      console.error('Error adding branch:', error);
      let errorMessage = 'An error occurred while adding the branch.';
      if (error.response) {
        const { status, data } = error.response;
        errorMessage = `Error ${status}: ${JSON.stringify(data)}`;
        if (data.errors && Array.isArray(data.errors)) {
          errorMessage += '\n' + data.errors.join('\n');
        }
      } else if (error.message) {
        errorMessage = error.message;
      }
      setError(errorMessage);
    }
  };

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate('/client-admin/branches');
    }
  };

  const inputClass = `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode 
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white' 
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`;

  const errorClass = 'text-red-500 text-sm mt-1';


  const renderField = (label, name,  step, options = [], type = 'text') => {
    if (name === 'company.name') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <select
              id={name}
              name={name}
              value={branch.company.id || ''}
              onChange={handleCompanyChange}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            >
              <option value="">Select Company</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
            {errors[name] && <span className={errorClass}>{errors[name]}</span>}
          </div>
        </div>
      );
    } else if (name === 'state') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <input
              type="number"
              id={name}
              name={name}
              value={branch[name]}
              onChange={handleInputChange}
              min="0"
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            />
            {errors[name] && <span className={errorClass}>{errors[name]}</span>}
          </div>
        </div>
      );
    } else if (name === 'company.id') {
        return (
          <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
            <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
              {label}
            </label>
            <div className="col-span-2">
              <input
                type="text"
                id={name}
                name={name}
                value={branch.company.id || ''}
                readOnly
                className={`${inputClass} ${errors[name] ? 'border-red-500' : ''} bg-gray-100`}
              />
              {errors[name] && <span className={errorClass}>{errors[name]}</span>}
            </div>
          </div>
        );
    } else if (name === 'id') {
        return (
          <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
            <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
              {label}
            </label>
            <div className="col-span-2 flex items-center">
              <input
                type="text"
                id={name}
                name={name}
                value={branch[name] || ''}
                onChange={handleInputChange}
                placeholder="Generate a guid"
                className={`${inputClass} ${errors[name] ? 'border-red-500' : ''} bg-gray-100 flex-grow`}
              />
              <button
                type="button"
                onClick={handleGenerateGuid}
                className={`ml-2 px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                  isDarkMode
                    ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                    : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
                }`}
              >
                Generate
              </button>
            </div>
            {errors[name] && <span className={errorClass}>{errors[name]}</span>}
          </div>
        );
      } else if (name === 'tel') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <PhoneInput
              country={'gb'}
              onlyCountries={['gb', 'ae']}
              value={branch[name]}
              onChange={(phone) => handleInputChange({ target: { name, value: phone } })}
              inputProps={{
                name: 'tel',
                id: 'tel',
                className: `${inputClass} w-full pl-[60px] ${errors[name] ? 'border-red-500' : ''}`,
              }}
              containerClass="w-full"
              buttonClass={`${isDarkMode ? 'bg-gray-700 border-gray-600' : ''} absolute top-0 left-0`}
              dropdownClass={`${isDarkMode ? 'bg-gray-700 text-white' : ''} !w-[300px]`}
            />
            {errors[name] && <span className={errorClass}>{errors[name]}</span>}
          </div>
        </div>
      );
    } else if (name === 'country') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <select
              id={name}
              name={name}
              value={branch[name] || ''}
              onChange={handleInputChange}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            >
              <option value="">Select Country</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
            </select>
            {errors[name] && <span className={errorClass}>{errors[name]}</span>}
          </div>
        </div>
      );
    } else if (name === 'accountcode') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2 flex items-center">
            <input
              type="text"
              id={name}
              name={name}
              value={branch[name] || ''}
              onChange={handleInputChange}
              placeholder="Generate a GUID"
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''} bg-gray-100 flex-grow`}
            />
            <button
              type="button"
              onClick={handleGenerateAccountcode}
              className={`ml-2 px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
              }`}
            >
              Generate
            </button>
          </div>
          {errors[name] && <span className={errorClass}>{errors[name]}</span>}
        </div>
      );
    } else if (name === 'branchName') {
        return (
          <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
            <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
              {label}
            </label>
            <div className="col-span-2">
              <input
                type="text"
                id={name}
                name={name}
                value={branch[name] || ''}
                onChange={handleInputChange}
                className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
              />
              {errors[name] && <span className={errorClass}>{errors[name]}</span>}
            </div>
          </div>
        );
    } else if (name === 'codes') {
      const validCodes = branch.codes ? branch.codes.filter(code => code.trim() !== "") : [];
      return (
        <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <div className="flex flex-wrap gap-2 mb-2">
              {validCodes.map((code, index) => (
                <span
                  key={index}
                  className={`px-3 py-2 rounded-md text-base flex items-center ${
                    isDarkMode
                      ? 'bg-blue-600 text-white'
                      : 'bg-blue-100 text-blue-800'
                  }`}
                >
                  {code}
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteCode(index);
                    }}
                    className="ml-2 focus:outline-none"
                  >
                    <X size={16} />
                  </button>
                </span>
              ))}
            </div>
            <div className="flex items-center">
              <input
                type="text"
                value={newCode}
                onChange={(e) => setNewCode(e.target.value)}
                className={`${inputClass} mr-2`}
                placeholder="Add new code"
              />
              <button
                type="button"
                onClick={handleAddCode}
                className={`px-3 py-2 rounded-md ${
                  isDarkMode
                    ? 'bg-green-600 text-white hover:bg-green-700'
                    : 'bg-green-100 text-green-800 hover:bg-green-200'
                }`}
              >
                <Plus size={20} />
              </button>
            </div>
          </div>
        </div>
      );
    }
    
    return (
      <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
        <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
        </label>
        <div className="col-span-2">
          {type === 'select' ? (
            <select
              id={name}
              name={name}
              value={branch[name] || ''}
              onChange={handleInputChange}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            >
              <option value="">Select {label}</option>
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <input
              type={type}
              id={name}
              name={name}
              value={branch[name] || ''}
              onChange={handleInputChange}
              step={step}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            />
          )}
          {errors[name] && <span className={errorClass}>{errors[name]}</span>}
        </div>
      </div>
    );
  };

  const renderDetailsTab = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            New Branch Information
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6 space-y-4">
          {renderField('Company Name', 'company.name')}
          {renderField('Company Id (Guid)', 'company.id')}
          {renderField('Branch Name', 'name')}
          {renderField('Branch Id (Guid)', 'id')}
          {renderField('Reference', 'reference')}
          {renderField('Address 1', 'address1')}
          {renderField('Address 2', 'address2')}
          {renderField('Town', 'town')}
          {renderField('County', 'county')}
          {renderField('Country', 'country')}
          {renderField('Zip/Post Code', 'zipPostCode')}
          {renderField('Account Code', 'accountcode')}
          {renderField('Telephone', 'tel')}
          {renderField('State', 'state')}
          {renderField('Codes', 'codes')}
        </div>
      </div>
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden mt-6">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            JSON Preview
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <JSONInput
            id="json-editor"
            placeholder={branch}
            locale={locale}
            height="400px"
            width="100%"
            viewOnly={true}
            theme={isDarkMode ? "dark_vscode_tribute" : "light_mitsuketa_tribute"}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="submit"
          className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
            isDarkMode
              ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
              : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
          }`}
        >
          Add Branch
        </button>
      </div>
    </form>
  );

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Add New Branch</h1>
          <button
            type="button"
            onClick={handleBackClick}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back
          </button>
        </div>
        
        {renderDetailsTab()}
      </div>
    </div>
  );
};

export default AddBranchPage;