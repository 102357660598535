import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ErrorBoundary = ({ children }) => {
  const [hasError] = useState(false);
  const [error] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (hasError) {
      navigate('/error', {
        state: {
          errorCode: 500,
          errorMessage: error?.message || 'An unexpected error occurred',
        },
      });
    }
  }, [hasError, error, navigate]);

  return (
    <React.Fragment>
      {hasError ? null : children}
    </React.Fragment>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
