import React, { useEffect, useState } from 'react';
import { Tag, Tooltip, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { InboxIcon, QuestionMarkCircleIcon, CpuChipIcon, EyeIcon } from '@heroicons/react/24/outline';
import { ConversationContactStatusNames, FilterTypes } from '../../utils/constants';
import PropTypes from 'prop-types';

const InboxTab = ({ matchedConversations, unknownMessages, fetchAiProcessingData, isDarkMode }) => {
  const [activeInboxTab, setActiveInboxTab] = useState('matched');
  const [aiProcessingMessages, setAiProcessingMessages] = useState([]);
  const [inboxCount, setInboxCount] = useState();

  useEffect(() => {
    const fetchInboxCount = async () => {
      const totalInboxCount = (matchedConversations?.length || 0) + (unknownMessages?.list?.length || 0) + (fetchAiProcessingData?.length || 0);
      setInboxCount(totalInboxCount);
    };

    fetchInboxCount();
  }, [matchedConversations, unknownMessages, fetchAiProcessingData]);


  const navigate = useNavigate();

  useEffect(() => {

    if (Array.isArray(fetchAiProcessingData)) {
      setAiProcessingMessages(fetchAiProcessingData);
    }
  }, [matchedConversations, unknownMessages, fetchAiProcessingData]);



  
  const getNestedValue = (obj, path) => {
    if (typeof path === 'string') {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    } else if (Array.isArray(path)) {
      return path.reduce((acc, part) => acc && acc[part], obj);
    }
    return undefined;
  };

  const getFilterTypeText = (filterType) => {
    return FilterTypes[filterType] || 'Unknown';
  };
 

  const trimName = (name, maxLength = 20) => {
    return name && name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
  };

  const tabClass = (tabName) => `
    px-4 py-2 font-medium text-sm rounded-t-lg
    ${activeInboxTab === tabName
      ? isDarkMode
        ? 'bg-gray-700 text-white'
        : 'bg-white text-blue-600 border-b-2 border-blue-600'
      : isDarkMode
      ? 'bg-gray-800 text-gray-300 hover:text-white hover:bg-gray-700'
      : 'bg-gray-100 text-gray-600 hover:text-gray-800 hover:bg-gray-200'
    }
  `;

  const columns = {
    matched: [
        {
        title: 'Conversation',
        dataIndex: ['conversation', 'name'],
        key: 'conversation',
        render: (_, record) => {
          const conversationName = getNestedValue(record, ['conversation', 'name']);
          const conversationProduct = getNestedValue(record, ['conversation', 'conversationProduct']);
          const conversationId = getNestedValue(record, ['conversation', 'id']);
          const displayText = `${conversationName} (${conversationProduct})`;
          
          return (
            <Tooltip title={displayText}>
              <Link
                to={`/client-admin/conversations/${conversationId}`}
                className={`text-black-600 dark:text-white-400 hover:underline`}
              >
                {displayText}
              </Link>
            </Tooltip>
          );
        },
      },
      {
        title: 'Contact',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => {
          const contactId = record.incomingMessage?.contactId;
          
          if (!contactId) {
            console.warn('Contact ID not found for record:', record);
            return `${record.firstName} ${record.lastName}`;
          }
          
          return (
            <Link
              to={`/client-admin/customer/${contactId}`}
              className={`text-black-600 dark:text-white-400 hover:underline`}
            >
              {`${record.firstName} ${record.lastName}`}
            </Link>
          );
        },
      },
      {
        title: 'Virtual Assistant',
        dataIndex: ['virtualAssistant', 'name'],
        key: 'vaName',
        render: (_, record) => (
          <Link
            to={`/client-admin/virtual-assistants/${record.virtualAssistant.id}`}
            className={`text-black-600 dark:text-white-400 hover:underline`}
          >
            {getNestedValue(record, ['virtualAssistant', 'name'])}
          </Link>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        render: (state) => {
          let color = 'green';
          let text = 'Active';
          if (state === 0) {
            color = 'orange';
            text = 'Inactive';
          }
          return <Tag color={color}>{text}</Tag>;
        },
      },
      {
        title: 'Content For Prediction',
        dataIndex: ['incomingMessage', 'contentForPrediction'],
        key: 'contentForPrediction',
        render: (content) => {
          if (!content) return 'N/A';
          const trimmedContent = content.split('\n')[0];
          return (
            <Tooltip title={content}>
              {trimName(trimmedContent, 25)}
            </Tooltip>
          );
        },
      },
      {
        title: 'Prediction',
        dataIndex: ['incomingMessage', 'responsePrediction', 'prediction'],
        key: 'prediction',
        render: (prediction) => {
          if (Array.isArray(prediction)) {
            return (
              <div className="flex flex-col">
                {prediction.map((p) => {
                  const status = ConversationContactStatusNames[p];
                  let color = 'blue';
                  if (p >= 100 && p < 200) color = 'green';
                  if (p >= 200 && p < 300) color = 'orange';
                  if (p >= 300 && p < 400) color = 'red';
                  if (p >= 400 && p < 500) color = 'purple';
                  if (p >= 500) color = 'cyan';
                  return <Tag key={p} color={color}>{status || `Unknown (${p})`}</Tag>;
                })}
              </div>
            );
          }
          return 'N/A';
        },
      },
      {
        title: 'Sent On',
        dataIndex: ['incomingMessage', 'sentOnUtc'],
        key: 'sentOn',
        render: (date) => (date ? new Date(date).toLocaleString() : 'N/A'),
      },
      {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Tooltip title="View Customer Details">
          <Button 
            icon={<EyeIcon className={`h-4 w-4 mr-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />} 
            className={`flex items-center justify-center ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-gray-300 border-gray-600' 
                : 'bg-white hover:bg-gray-100 text-gray-700 border-gray-300'
            }`}
            size="small"
            onClick={() => navigate(`/client-admin/customer/${record.incomingMessage.contactId}`)}
          >
            View
          </Button>
        </Tooltip>
      ),
    },
    ],
    unknown: [
      {
        title: 'Sender Email',
        dataIndex: 'senderEmail',
        key: 'senderEmail',
        render: (senderEmail, record) => {
          const contactId = record.contact?.id;
          return (
            <Tooltip title={senderEmail}>
              <Link
                to={`/client-admin/customer/${contactId}`}
                className={`text-black-600 dark:text-white-400 hover:underline`}
              >
                {trimName(senderEmail, 30)}
              </Link>
            </Tooltip>
          );
        },
      },
      {
        title: 'Recipient Name',
        dataIndex: ['virtualAssistant', 'name'],
        key: 'vaName',
        render: (_, record) => (
          <Link
            to={`/client-admin/virtual-assistants/${record.virtualAssistant.id}`}
            className={`text-black-600 dark:text-white-400 hover:underline`}
          >
            {getNestedValue(record, ['virtualAssistant', 'name']) || 'N/A'}
          </Link>
        ),
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        render: (subject) => (
          <Tooltip title={subject}>
            {trimName(subject, 30)}
          </Tooltip>
        ),
      },
      {
        title: 'Sent On',
        dataIndex: 'sentOnUtc',
        key: 'sentOn',
        render: (date) => (date ? new Date(date).toLocaleString() : 'N/A'),
      },
      {
        title: 'Filter Type',
        dataIndex: 'filterType',
        key: 'filterType',
        render: (filterType) => getFilterTypeText(filterType),
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (state) => {
          let color = 'green';
          let text = 'Active';
          if (state === 1) {
            color = 'orange';
            text = 'Inactive';
          }
          return <Tag color={color}>{text}</Tag>;
        },
      },
      {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Tooltip title="View Customer Details">
          <Button 
            icon={<EyeIcon className={`h-4 w-4 mr-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />} 
            className={`flex items-center justify-center ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-gray-300 border-gray-600' 
                : 'bg-white hover:bg-gray-100 text-gray-700 border-gray-300'
            }`}
            size="small"
            onClick={() => navigate(`/client-admin/customer/${record.contact?.id}`)}
          >
            View
          </Button>
        </Tooltip>
      ),
    },
    ], 
  aiProcessing: [
      {
        title: 'Contact Name',
        dataIndex: ['contact', 'firstName', 'lastName'],
        key: 'contactName',
        render: (_, record) => {
          const firstName = getNestedValue(record, ['contact', 'firstName']) || '';
          const lastName = getNestedValue(record, ['contact', 'lastName']) || '';
          const contactId = record.contact?.id;

          if (!contactId) {
            console.warn('Contact ID not found for record:', record);
            return `${firstName} ${lastName}`.trim() || 'N/A';
          }

          return (
            <Link
              to={`/client-admin/customer/${contactId}`}
              className={`text-black-600 dark:text-white-400 hover:underline`}
            >
              {`${firstName} ${lastName}`.trim() || 'N/A'}
            </Link>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: ['contact', 'updatedUsabilityStatus'],
        key: 'status',
        render: (status, record) => {
          const statusCode = getNestedValue(record, ['contact', 'updatedUsabilityStatus']);
          const statusName = ConversationContactStatusNames[statusCode];
          let color = 'blue';
          if (statusCode >= 100 && statusCode < 200) color = 'green';
          if (statusCode >= 200 && statusCode < 300) color = 'orange';
          if (statusCode >= 300 && statusCode < 400) color = 'red';
          if (statusCode >= 400 && statusCode < 500) color = 'purple';
          if (statusCode >= 500) color = 'cyan';
          return <Tag color={color}>{statusName || `Unknown (${statusCode})`}</Tag>;
        },
      },
      {
        title: 'Conversation',
        dataIndex: ['conversation', 'name'],
        key: 'conversationName',
        render: (_, record) => {
          const conversationName = getNestedValue(record, ['conversation', 'name']) || 'N/A';
          const conversationId = getNestedValue(record, ['conversation', 'id']);
          
          return conversationId ? (
            <Tooltip title={conversationName}>
              <Link
                to={`/client-admin/conversation/${conversationId}`}
                className={`text-black-600 dark:text-white-400 hover:underline`}
              >
                {trimName(conversationName, 30)}
              </Link>
            </Tooltip>
          ) : (
            conversationName
          );
        },
      },
      {
        title: 'Virtual Assistant',
        dataIndex: ['virtualAssistant', 'name'],
        key: 'assistant',
        render: (_, record) => (
          <Link
            to={`/client-admin/virtual-assistants/${record.virtualAssistant.id}`}
            className={`text-black-600 dark:text-white-400 hover:underline`}
          >
            {getNestedValue(record, ['virtualAssistant', 'name']) || 'N/A'}
          </Link>
        ),
      },
      {
        title: 'Message',
        dataIndex: 'contentForPrediction',
        key: 'contentForPrediction',
        render: (message) => (
          <Tooltip title={message}>
            <span>{trimName(message, 30)}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Created On',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (date) => (date ? new Date(date).toLocaleString() : 'N/A'),
      },
      {
      title: 'Actions',
      key: 'actions',
      render: () => (
        <Tooltip title="View Customer Details">
          <Button 
            icon={<EyeIcon className={`h-4 w-4 mr-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />} 
            className={`flex items-center justify-center ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-gray-300 border-gray-600' 
                : 'bg-white hover:bg-gray-100 text-gray-700 border-gray-300'
            }`}
            size="small"
            // onClick={() => handleViewClick(record)}
          >
            View
          </Button>
        </Tooltip>
      ),
    },
    ],
  };

const renderInboxTabContent = () => {
    let conversations;
    if (activeInboxTab === 'matched') {
      conversations = Array.isArray(matchedConversations) ? matchedConversations : [];
    } else if (activeInboxTab === 'unknown') {
      conversations = Array.isArray(unknownMessages?.list) ? unknownMessages.list : [];
    } else {
      conversations = aiProcessingMessages;
    }


    const currentColumns = columns[activeInboxTab];
    const emptyIcon = activeInboxTab === 'matched' ? InboxIcon : activeInboxTab === 'unknown' ? QuestionMarkCircleIcon : CpuChipIcon;
    const emptyTitle = activeInboxTab === 'matched' ? 'No matched conversations' : activeInboxTab === 'unknown' ? 'No unknown messages' : 'No AI processing messages';
    const emptyMessage = activeInboxTab === 'matched'
      ? 'There are no matched conversations in your inbox at the moment.'
      : activeInboxTab === 'unknown'
      ? 'There are no unknown messages in your inbox at the moment.'
      : 'There are no AI processing messages at the moment.';

    if (conversations.length === 0) {
      return (
        <div className="text-center py-12">
          {React.createElement(emptyIcon, {
            className: `mx-auto h-12 w-12 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`
          })}
          <h3 className={`mt-2 text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-900'}`}>{emptyTitle}</h3>
          <p className={`mt-1 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {emptyMessage}
          </p>
        </div>
      );
    }

    return (
      <div>
        <h4 className={`text-lg leading-6 font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-4`}>
          {activeInboxTab === 'matched' ? 'Matched Conversations' : activeInboxTab === 'unknown' ? 'Unknown Messages' : 'AI Processing Messages'}
        </h4>
        <div className={`overflow-x-auto ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg`}>
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
              <tr>
                {currentColumns.map((column) => (
                  <th
                    key={column.key}
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium ${
                      isDarkMode ? 'text-gray-300 uppercase tracking-wider' : 'text-gray-500 uppercase tracking-wider'
                    }`}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} divide-y divide-gray-200 dark:divide-gray-700`}>
              {conversations.map((item) => (
                <tr key={item.id} className={isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}>
                  {currentColumns.map((column) => (
                    <td
                      key={column.key}
                      className={`px-6 py-4 whitespace-nowrap text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-500'
                      }`}
                    >
                      {column.render
                        ? column.render(getNestedValue(item, column.dataIndex), item)
                        : getNestedValue(item, column.dataIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className={`bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden`}>
      <div className="px-4 py-5 sm:p-6">
        {/* <div className="flex justify-between items-center mb-4"> */}
          <h3 className={`text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4`}>
            Inbox
          </h3>
          <span className={`text-sm font-medium text-gray-500 dark:text-gray-300`}>
            {`Total Messages: ${inboxCount}`}
          </span>
        {/* </div> */}
        <div className={`border-b border-gray-200 dark:border-gray-700 mb-4`}>
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                className={tabClass('matched')}
                onClick={() => setActiveInboxTab('matched')}
              >
                Matched
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('unknown')}
                onClick={() => setActiveInboxTab('unknown')}
              >
                Unknown
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('aiProcessing')}
                onClick={() => setActiveInboxTab('aiProcessing')}
              >
                AI Processing
              </button>
            </li>
          </ul>
        </div>
        <div>
          {renderInboxTabContent()}
        </div>
      </div>
    </div>
  );
};

InboxTab.propTypes = {
  matchedConversations: PropTypes.array.isRequired,
  unknownMessages: PropTypes.object.isRequired,
  fetchAiProcessingData: PropTypes.array.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};

export default InboxTab;