import React from 'react';
import { useTheme } from '../../contexts/ThemeContext'; 
import PropTypes from 'prop-types';

const ClientTabs = ({ activeTab, setActiveTab }) => {
  const { isDarkMode } = useTheme();

const tabClass = (tabName) => {
  let activeClass = '';
  let inactiveClass = '';

  if (activeTab === tabName) {
    if (isDarkMode) {
      activeClass = 'bg-gray-700 text-white';
    } else {
      activeClass = 'bg-white text-blue-600 border-b-2 border-blue-600';
    }
  }

  if (activeTab !== tabName) {
    if (isDarkMode) {
      inactiveClass = 'bg-gray-600 text-gray-300 hover:text-white hover:bg-gray-500';
    } else {
      inactiveClass = 'bg-gray-200 text-gray-600 hover:text-gray-800 hover:bg-gray-300';
    }
  }

  return `px-4 py-2 font-medium text-sm rounded-t-lg ${activeTab === tabName ? activeClass : inactiveClass}`;
};

  return (
    <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px">
        <li className="mr-2">
          <button className={tabClass('details')} onClick={() => setActiveTab('details')}>Details</button>
        </li>
        <li className="mr-2">
          <button className={tabClass('aiInfo')} onClick={() => setActiveTab('aiInfo')}>AI Info</button>
        </li>
        <li className="mr-2">
          <button className={tabClass('va')} onClick={() => setActiveTab('va')}>Virtual Assistants</button>
        </li>
        <li className="mr-2">
          <button className={tabClass('holidays')} onClick={() => setActiveTab('holidays')}>Holidays</button>
        </li>
        <li className="mr-2">
          <button className={tabClass('inbox')} onClick={() => setActiveTab('inbox')}>Inbox</button>
        </li>
        <li className="mr-2">
          <button className={tabClass('outbox')} onClick={() => setActiveTab('outbox')}>Outbox</button>
        </li>
        <li className="mr-2">
          <button className={tabClass('logs')} onClick={() => setActiveTab('logs')}>Logs</button>
        </li>
      </ul>
    </div>
  );
};

ClientTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
};

export default ClientTabs;