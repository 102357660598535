import React, { useState } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { grabToken } from '../../utils/api';
import PropTypes from 'prop-types';

const AiInfoTab = ({ client, setClient, isDarkMode }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(client.description || '');

  const handleSubmit = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = grabToken();
      const response = await axios.put(
        `${apiBaseUrl}/Companies`,
        {
          ...client,
          description: description
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setClient({ ...client, description: description });
        setIsEditing(false);
        notification.success({
          message: 'AI Information updated successfully'
        });
      } else {
        throw new Error('Failed to update AI Information');
      }
    } catch (error) {
      console.error('Error updating AI Information:', error);
      notification.error({
        message: 'Failed to update AI Information',
        description: error.response?.data?.errors?.[0] || 'An unexpected error occurred'
      });
    }
  };

  return (
    <div className={`bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden`}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className={`text-lg leading-6 font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-4`}>
          AI Information
        </h3>
        <div className="space-y-4">
          <div className="flex items-center">
            <textarea
              id="description"
              name="description"
              rows={20}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={!isEditing}
              className={`w-full p-2 border rounded-lg ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-600' 
                  : 'bg-white text-black border-gray-300'
              }`}
            />
            <button
              type="button"
              onClick={() => {
                if (isEditing) {
                  handleSubmit();
                } else {
                  setIsEditing(true);
                }
              }}
              className={`ml-2 px-3 py-1 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
              }`}
            >
              {isEditing ? 'Save' : 'Edit'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AiInfoTab.propTypes = {
  client: PropTypes.object.isRequired,
  setClient: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default AiInfoTab;