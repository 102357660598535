import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import MonacoEditor from 'react-monaco-editor';
import { notification } from 'antd';
import { format, isValid, parseISO } from 'date-fns';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ErrorPage from '../../../pages/Error/ErrorPage';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  endpoints: {
    aiModels: '/predictionModels',
  },
};

const AIModelDetailPage = () => {
  const [model, setModel] = useState({
    id: '',
    aiServiceId: 0,
    type: 0,
    countryCode: '',
    note: '',
    targetName: '',
    narrative: '',
    outputFormat: '',
    createdDate: '',
    updatedDate: '',
    createdByUser: { id: '', name: '' },
    updatedByUser: { id: '', name: '' },
  });
  
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('details');
  const [editedJsonString, setEditedJsonString] = useState('');
  const [isJsonValidated, setIsJsonValidated] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (id) {
      fetchModelDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (model.narrative) {
      const initialJsonString = JSON.stringify(JSON.parse(model.narrative), null, 2);
      setEditedJsonString(initialJsonString);
    }
  }, [model.narrative]);

  const fetchModelDetails = async (modelId) => {
    setLoading(true);
    setError(null);
    try {
      const token = grabToken();
      const response = await axios.get(`${API_CONFIG.baseURL}${API_CONFIG.endpoints.aiModels}/${modelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setModel(response.data.result);
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModel(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleJsonChange = (value) => {
    setEditedJsonString(value);
    setIsJsonValidated(false);
  };

  const tabClass = (tabName) => `
  px-4 py-2 font-medium text-sm rounded-t-lg
  ${activeTab === tabName
    ? isDarkMode
      ? 'bg-gray-700 text-white'
      : 'bg-white text-blue-600'
    : isDarkMode
    ? 'bg-gray-600 text-gray-300 hover:bg-gray-500'
    : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
  }
`;

  const handleValidateClick = () => {
    try {
      JSON.parse(editedJsonString);
      setIsJsonValidated(true);
      notification.success({
        message: 'JSON is valid',
      });
    } catch (error) {
      setIsJsonValidated(false);
      notification.error({
        message: 'Invalid JSON',
        description: error.message,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isJsonValidated) {
      notification.warning({
        message: 'Please validate the JSON before saving',
      });
      return;
    }
    setLoading(true);
    try {
      const token = grabToken();
      const updatedModel = {
        ...model,
        narrative: editedJsonString,
      };
      await axios.put(
        `${API_CONFIG.baseURL}${API_CONFIG.endpoints.aiModels}/${model.id}`,
        updatedModel,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setIsEditing(false);
      notification.success({
        message: 'AI Model was updated',
      });
      fetchModelDetails(model.id);
    } catch (error) {
      console.error('Error updating AI Model:', error);
      notification.error({
        message: 'Failed to update AI Model',
        description: error.response?.data || error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = () => setIsEditing(true);
  const handleCancelEdit = () => {
    setIsEditing(false);
    fetchModelDetails(model.id);
  };

  const inputClass = `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode 
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white' 
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`;

  const getTypeDisplay = (type) => {
    switch (type) {
      case 15:
        return "AI Reply Prediction";
      case 16:
        return "AI Labels Prediction";
      default:
        return type.toString();
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = typeof dateString === 'string' ? parseISO(dateString) : new Date(dateString);
    
    if (!isValid(date)) {
      console.error(`Invalid date: ${dateString}`);
      return 'Invalid Date';
    }
    
    try {
      return format(date, "dd MMM yyyy HH:mm:ss");
    } catch (error) {
      console.error(`Error formatting date: ${dateString}`, error);
      return 'Date Error';
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage errorCode={error.code} errorMessage={error.message} />;

  const renderMetadataField = (label, user, date) => (
    <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
      <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
      <div className="col-span-2">
        <p className="text-sm text-gray-900 dark:text-gray-100">
          {user.name} <span className="text-gray-500">on</span> {formatDate(date)}
        </p>
      </div>
    </div>
  );


  const renderField = (label, name) => (
    <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
      <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
      <div className="col-span-2">
        <input
          type="text"
          id={name}
          name={name}
          value={name === 'type' ? getTypeDisplay(model[name]) : (model[name] || '')}
          onChange={handleInputChange}
          disabled={!isEditing || name === 'type' || name === 'createdByUser.name' || name === 'updatedByUser.name' || name === 'createdDate' || name === 'updatedDate'}
          className={`${inputClass} ${name === 'type' ? 'bg-gray-100 dark:bg-gray-600' : ''}`}
        />
      </div>
    </div>
  );

  const renderDetailsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6 space-y-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          AI Model Details
        </h3>
        {renderField('Name', 'note')}
        {renderField('Model Type', 'type')}
        {renderField('Country Code', 'countryCode')}
        {renderField('Model', 'targetName')}
        {renderMetadataField('Created By', model.createdByUser, model.createdDate)}
        {renderMetadataField('Updated By', model.updatedByUser, model.updatedDate)}
      </div>
    </div>
  );

    
    const renderAiPromptTab = () => {
      const narrativeJson = JSON.parse(model.narrative);
      const outputFormatJson = JSON.parse(model.outputFormat);
    
      const extractKeywords = (text) => {
        const regex = /\{\[(.*?)\]\}/g;
        const matches = [...text.matchAll(regex)];
        return matches.map(match => match[1]);
      };
    
      const allKeywords = new Set();
      Object.values(narrativeJson).forEach(value => {
        if (typeof value === 'string') {
          extractKeywords(value).forEach(keyword => allKeywords.add(keyword));
        }
      });
    
      const editorOptions = {
        minimap: { enabled: false },
        automaticLayout: true,
        wordWrap: 'on',
        wrappingStrategy: 'advanced',
        wrappingIndent: 'indent',
        lineNumbers: 'off',
        folding: false,
        scrollBeyondLastLine: false,
        formatOnPaste: true,
        formatOnType: true,
      };
    
      return (
        <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
              AI Prompt Configuration
            </h3>
            
            <div className="mb-6">
              <h4 className="text-md font-semibold mb-2">Keywords</h4>
              <div className="flex flex-wrap gap-2">
                {Array.from(allKeywords).map((keyword, index) => (
                  <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded">
                    {keyword}
                  </span>
                ))}
              </div>
            </div>
    
            <h4 className="text-md font-semibold mt-4 mb-2">Narrative</h4>
            <div className="border rounded-md overflow-hidden mb-4">
              <MonacoEditor
                width="100%"
                height="400px"
                language="json"
                theme={isDarkMode ? 'vs-dark' : 'vs-light'}
                value={editedJsonString}
                onChange={handleJsonChange}
                options={{
                  ...editorOptions,
                  readOnly: !isEditing,
                }}
              />
            </div>
    
            <h4 className="text-md font-semibold mt-4 mb-2">Output Format</h4>
            <div className="border rounded-md overflow-hidden">
              <MonacoEditor
                width="100%"
                height="200px"
                language="json"
                theme={isDarkMode ? 'vs-dark' : 'vs-light'}
                value={JSON.stringify(outputFormatJson, null, 2)}
                options={{
                  ...editorOptions,
                  readOnly: true,
                }}
              />
            </div>
    
            {isEditing && (
              <button
                type="button"
                onClick={handleValidateClick}
                className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Validate JSON
              </button>
            )}
          </div>
        </div>
      );
    };

  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">AI Model Details</h1>
          <button
            type="button"
            onClick={() => navigate('/internal-admin/aiml')}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back to AI Models
          </button>
        </div>

        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                className={tabClass('details')}
                onClick={() => setActiveTab('details')}
              >
                Details
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('aiPrompt')}
                onClick={() => setActiveTab('aiPrompt')}
              >
                AI Prompt
              </button>
            </li>
          </ul>
        </div>

        {activeTab === 'details' ? renderDetailsTab() : renderAiPromptTab()}

        <div className="mt-6 flex justify-between">
          {isEditing ? (
            <>
              <button
                type="button"
                onClick={handleSubmit}
                className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save Changes
              </button>
              <button
                type="button"
                onClick={handleCancelEdit}
                className="px-6 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={handleEditClick}
              className="px-6 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
            >
              Edit AI Model
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AIModelDetailPage;