import React from 'react';
import { Outlet } from 'react-router-dom';
import DefaultLayout from './DefaultLayout';
import MinimalLayout from './MinimalLayout';
import FullWidthLayout from './FullWidthLayout';
import PropTypes from 'prop-types';

const layouts = {
  default: DefaultLayout,
  minimal: MinimalLayout,
  fullWidth: FullWidthLayout,
};

const LayoutWrapper = ({ layout = 'default' }) => {
  const Layout = layouts[layout] || DefaultLayout;
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

LayoutWrapper.propTypes = {
  layout: PropTypes.string,
};

export default LayoutWrapper;