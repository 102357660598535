import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import { notification } from 'antd';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  endpoints: {
    providers: '/EmailProviders',
  },
};

const ProviderDetailPage = () => {
  const [provider, setProvider] = useState({
    id: '',
    name: '',
    enforceGraphApi: false,
    createdDate: '',
    updatedDate: '',
    createdByUser: { id: '', name: '' },
    updatedByUser: { id: '', name: '' },
    sender: { server: '', port: 0, sslEnabled: false },
    recipient: { server: '', port: 0, sslEnabled: false },
    state: 0,
  });
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (id) {
      fetchProviderDetails(id);
    }
  }, [id]);

  const fetchProviderDetails = async (providerId) => {
    setLoading(true);
    setError(null);
    try {
      const token = grabToken();
      const response = await axios.get(`${API_CONFIG.baseURL}${API_CONFIG.endpoints.providers}/${providerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setProvider(response.data.result);
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProvider(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = grabToken();
      await axios.put(
        `${API_CONFIG.baseURL}${API_CONFIG.endpoints.providers}/${provider.id}`,
        provider,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setIsEditing(false);
      notification.success({
        message: 'Provider was updated',
      });
      fetchProviderDetails(provider.id);
    } catch (error) {
      console.error('Error updating Provider:', error);
      notification.error({
        message: 'Failed to update Provider',
        description: error.response?.data || error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const inputClass = `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode 
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white' 
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`;


  const renderField = (label, name, type = 'text', disabled = false) => (
    <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
      <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
      <div className="col-span-2">
        {type === 'checkbox' ? (
          <input
            type="checkbox"
            id={name}
            name={name}
            checked={provider[name]}
            onChange={handleInputChange}
            disabled={!isEditing || disabled}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
        ) : (
          <input
            type={type}
            id={name}
            name={name}
            value={provider[name] || ''}
            onChange={handleInputChange}
            disabled={!isEditing || disabled}
            className={inputClass}
          />
        )}
      </div>
    </div>
  );

  const renderServerField = (label, serverType, field) => (
    <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
      <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
      <div className="col-span-2">
        <input
          type="text"
          value={provider[serverType][field]}
          onChange={(e) => setProvider(prev => ({
            ...prev,
            [serverType]: { ...prev[serverType], [field]: e.target.value }
          }))}
          disabled={!isEditing}
          className={inputClass}
        />
      </div>
    </div>
  );

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-red-500 font-bold text-center">{error}</div>;

  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Email Provider Details</h1>
          <button
            type="button"
            onClick={() => navigate('/internal-admin/providers')}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back to Providers
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:p-6 space-y-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
                Provider Information
              </h3>
              {renderField('Name', 'name')}
              <h4 className="text-md font-medium text-gray-900 dark:text-white mt-4">Sender</h4>
              {renderServerField('Server', 'sender', 'server')}
              {renderServerField('Port', 'sender', 'port')}
              {renderServerField('SSL Enabled', 'sender', 'sslEnabled')}
              <h4 className="text-md font-medium text-gray-900 dark:text-white mt-4">Recipient</h4>
              {renderServerField('Server', 'recipient', 'server')}
              {renderServerField('Port', 'recipient', 'port')}
              {renderServerField('SSL Enabled', 'recipient', 'sslEnabled')}
            </div>          
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProviderDetailPage;