import React, { useState, useEffect } from 'react';
import { Tag, Tooltip, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { InboxIcon, EyeIcon } from '@heroicons/react/24/outline';
import { ConversationContactStatusNames } from '../../utils/constants';
import PropTypes from 'prop-types';

const OutboxTab = ({ outboundMessages, isDarkMode }) => {
  const [activeOutboxTab, setActiveOutboxTab] = useState('all');
  const [outboxCount, setOutboxCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Outbound Messages:', outboundMessages);
  }, [outboundMessages]);

  useEffect(() => {
    const fetchInboxCount = async () => {
      const totalOutboxCount = (outboundMessages?.length || 0)
      setOutboxCount(totalOutboxCount);
    };

    fetchInboxCount();
  }, [outboundMessages]);


  const getNestedValue = (obj, path) => {
    if (typeof path === 'string') {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    } else if (Array.isArray(path)) {
      return path.reduce((acc, part) => acc && acc[part], obj);
    }
    return undefined;
  };

  const trimName = (name, maxLength = 20) => {
    return name && name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
  };

  const handleViewClick = (record) => {
    if (record && record.emails && Array.isArray(record.emails)) {
      const filteredEmail = record.emails.find(email => email.contentForPrediction);
      if (filteredEmail) {
        navigate(`/client-admin/customer/${record.contact.id}`, {
          state: { filteredEmail }
        });
      } else {
        // If no email with contentForPrediction is found, navigate without filtered data
        navigate(`/client-admin/customer/${record.contact.id}`);
      }
    } else {
      // If no emails array is found, navigate without any email data
      navigate(`/client-admin/customer/${record.contact.id}`);
    }
  };

  const tabClass = (tabName) => `
    px-4 py-2 font-medium text-sm rounded-t-lg
    ${activeOutboxTab === tabName
      ? isDarkMode
        ? 'bg-gray-700 text-white'
        : 'bg-white text-blue-600 border-b-2 border-blue-600'
      : isDarkMode
      ? 'bg-gray-800 text-gray-300 hover:text-white hover:bg-gray-700'
      : 'bg-gray-100 text-gray-600 hover:text-gray-800 hover:bg-gray-200'
    }
  `;

  const columns = [
     {
      title: 'Contact Name',
      dataIndex: 'contact',
      key: 'contactName',
      render: (contact, record) => {
        const fullName = `${contact.title || ''} ${contact.firstName || ''} ${contact.lastName || ''}`.trim();
        const contactId = getNestedValue(record, ['contact', 'id']);
        
        return contactId ? (
          <Tooltip title={fullName}>
            <Link
              to={`/client-admin/customer/${contactId}`}
              className="text-black-600 dark:text-white-400 hover:underline"
            >
              {trimName(fullName, 30)}
            </Link>
          </Tooltip>
        ) : (
          <Tooltip title={fullName}>
            {trimName(fullName, 30)}
          </Tooltip>
        );
      },
    },
    {
    title: 'Status',
    dataIndex: ['contact', 'updatedUsabilityStatuses'],
    key: 'status',
    render: (statusCodes) => {
        const codes = Array.isArray(statusCodes) ? statusCodes : [];
        
        if (codes.length === 0) {
        return <Tag color="default">No Status</Tag>;
        }

        return (
        <div className="flex flex-col space-y-1">
            {codes.map((statusCode, index) => {
            const statusName = ConversationContactStatusNames[statusCode];
            let color = 'blue';
            if (statusCode >= 100 && statusCode < 200) color = 'green';
            if (statusCode >= 200 && statusCode < 300) color = 'orange';
            if (statusCode >= 300 && statusCode < 400) color = 'red';
            if (statusCode >= 400 && statusCode < 500) color = 'purple';
            if (statusCode >= 500) color = 'cyan';
            return (
                <Tag key={index} color={color}>
                {statusName || `Unknown (${statusCode})`}
                </Tag>
            );
            })}
        </div>
        );
    },
    },
    {
    title: 'Conversation',
    dataIndex: 'conversation',
    key: 'name',
    render: (conversation) => (
      <Tooltip title={conversation.name}>
        <Link
          to={`/client-admin/conversation/${conversation.id}`}
          className={`text-black-600 dark:text-white-400 hover:underline`}
        >
          {trimName(conversation.name, 30)}
        </Link>
      </Tooltip>
    ),
    }, 
    {
      title: 'Virtual Assistant',
      dataIndex: ['virtualAssistant', 'name'],
      key: 'vaName',
      render: (_, record) => (
        <Link
          to={`/client-admin/virtual-assistants/${record.virtualAssistant.id}`}
          className={`text-black-600 dark:text-white-400 hover:underline`}
        >
          {getNestedValue(record, ['virtualAssistant', 'name']) || 'N/A'}
        </Link>
      ),
    },
    {
      title: 'Created On',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (date) => (date ? new Date(date).toLocaleString() : 'N/A'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Tooltip title="View Customer Details">
          <Button 
            icon={<EyeIcon className={`h-4 w-4 mr-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />} 
            className={`flex items-center justify-center ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-gray-300 border-gray-600' 
                : 'bg-white hover:bg-gray-100 text-gray-700 border-gray-300'
            }`}
            size="small"
            onClick={() => handleViewClick(record)}
          >
            View
          </Button>
        </Tooltip>
      ),
    },
  ];

  const renderOutboxTabContent = () => {
    if (!Array.isArray(outboundMessages) || outboundMessages.length === 0) {
      return (
        <div className="text-center py-12">
          <InboxIcon className={`mx-auto h-12 w-12 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          <h3 className={`mt-2 text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-900'}`}>No outbound messages</h3>
          <p className={`mt-1 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            There are no outbound messages at the moment.
          </p>
        </div>
      );
    }

    return (
      <div>
        <h4 className={`text-lg leading-6 font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-4`}>
          Outbound Messages
        </h4>
        <div className={`overflow-x-auto ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg`}>
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium ${
                      isDarkMode ? 'text-gray-300 uppercase tracking-wider' : 'text-gray-500 uppercase tracking-wider'
                    }`}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} divide-y divide-gray-200 dark:divide-gray-700`}>
              {outboundMessages.map((item) => (
                <tr key={item.id} className={isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}>
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className={`px-6 py-4 whitespace-nowrap text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-500'
                      }`}
                    >
                      {column.render
                        ? column.render(getNestedValue(item, column.dataIndex), item)
                        : getNestedValue(item, column.dataIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className={`bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden`}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className={`text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4`}>
          Outbox
        </h3>
        <span className={`text-sm font-medium text-gray-500 dark:text-gray-300`}>
           {`Total Outbox Messages: ${outboxCount}`}
        </span>
        <div className={`border-b border-gray-200 dark:border-gray-700 mb-4`}>
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                className={tabClass('all')}
                onClick={() => setActiveOutboxTab('all')}
              >
                All
              </button>
            </li>
            {/**/}
          </ul>
        </div>
        <div>
          {renderOutboxTabContent()}
        </div>
      </div>
    </div>
  );
};

OutboxTab.propTypes = {
  outboundMessages: PropTypes.array.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default OutboxTab;