import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker, Select, notification } from 'antd';
import axios from 'axios';
import { grabToken } from '../../utils/api';
import PropTypes from 'prop-types';

const { Option } = Select;

const HolidaysTab = ({ holidays, setHolidays, isDarkMode, clientId }) => {
  const [isHolidayModalVisible, setIsHolidayModalVisible] = useState(false);
  const [isShortDayModalVisible, setIsShortDayModalVisible] = useState(false);
  const [holidayForm] = Form.useForm();
  const [shortDayForm] = Form.useForm();
  
  /**
 * Formats a date string to a localized format.
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric' 
    };
    return date.toLocaleDateString('en-GB', options);
  };

  const handleAddHoliday = async (values) => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = grabToken();
      const response = await axios.post(
        `${apiBaseUrl}/ConversationHolidays/${clientId}`,
        {
          ...values,
          date: values.date.toISOString(),
          mode: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setHolidays([...holidays, response.data]);
        setIsHolidayModalVisible(false);
        holidayForm.resetFields();
        notification.success({ message: 'Holiday added successfully' });
      }
    } catch (error) {
      console.error('Error adding holiday:', error);
      notification.error({ message: 'Failed to add holiday' });
    }
  };

  const handleAddShortDay = async (values) => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = grabToken();
      const response = await axios.post(
        `${apiBaseUrl}/ConversationHolidays/${clientId}`,
        {
          ...values,
          date: values.date.toISOString(),
          mode: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setHolidays([...holidays, response.data]);
        setIsShortDayModalVisible(false);
        shortDayForm.resetFields();
        notification.success({ message: 'Short day added successfully' });
      }
    } catch (error) {
      console.error('Error adding short day:', error);
      notification.error({ message: 'Failed to add short day' });
    }
  };

  return (
    <div className="flex flex-col">
      <div className="w-full">
        <div className={`bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden`}>
          <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
            <div className="flex justify-between items-center mb-1">
              <h3 className={`text-lg leading-6 font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Holidays
              </h3>
              <div className="space-x-2">
                <button
                  onClick={() => setIsHolidayModalVisible(true)}
                  className={`px-3 py-1 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                    isDarkMode
                      ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                      : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
                  }`}
                >
                  Add New Holiday
                </button>
                <button
                  onClick={() => setIsShortDayModalVisible(true)}
                  className={`px-3 py-1 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                    isDarkMode
                      ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                      : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
                  }`}
                >
                  Add Short Day
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6 overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
              <thead className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <tr>
                  <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>Date</th>
                  <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>Holiday</th>
                  <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>Type</th>
                </tr>
              </thead>
              <tbody className={`${isDarkMode ? 'bg-gray-700' : 'bg-white'} divide-y divide-gray-200 dark:divide-gray-600`}>
                {holidays.map((holiday) => (
                  <tr key={holiday.id}>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {formatDate(holiday.date)}
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>{holiday.note}</td>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                      {holiday.countryCode === 'ae'
                        ? 'United Arab Emirates Holiday'
                        : holiday.countryCode === 'uk'
                        ? 'UK Holiday'
                        : holiday.countryCode}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        title="Add New Holiday"
        visible={isHolidayModalVisible}
        onCancel={() => setIsHolidayModalVisible(false)}
        footer={null}
      >
        <Form form={holidayForm} onFinish={handleAddHoliday}>
          <Form.Item name="date" label="Date" rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item name="note" label="Holiday Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="countryCode" label="Country" rules={[{ required: true }]}>
            <Select>
              <Option value="uk">UK</Option>
              <Option value="ae">UAE</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <button type="submit" className={`px-4 py-2 rounded-md ${
              isDarkMode ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}>
              Add Holiday
            </button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Add Short Day"
        visible={isShortDayModalVisible}
        onCancel={() => setIsShortDayModalVisible(false)}
        footer={null}
      >
        <Form form={shortDayForm} onFinish={handleAddShortDay}>
          <Form.Item name="date" label="Date" rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item name="note" label="Short Day Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="countryCode" label="Country" rules={[{ required: true }]}>
            <Select>
              <Option value="uk">UK</Option>
              <Option value="ae">UAE</Option>
            </Select>
          </Form.Item>
          <Form.Item name="workingTimeFrom" label="Working Time From" rules={[{ required: true }]}>
            <Input type="time" />
          </Form.Item>
          <Form.Item name="workingTimeTo" label="Working Time To" rules={[{ required: true }]}>
            <Input type="time" />
          </Form.Item>
          <Form.Item>
            <button type="submit" className={`px-4 py-2 rounded-md ${
              isDarkMode ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}>
              Add Short Day
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

HolidaysTab.propTypes = {
  holidays: PropTypes.object.isRequired,
  setHolidays: PropTypes.object.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
};

export default HolidaysTab;