import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { notification } from 'antd';
import { grabToken } from '../../utils/api';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';

const DetailsTab = ({ client, systems, branches, isDarkMode }) => {
  const navigate = useNavigate();
  const [isEditingReference, setIsEditingReference] = useState(false);
  const [editedClient, setEditedClient] = useState(client);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedClient(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmitReference = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = grabToken();
      const response = await axios.put(
        `${apiBaseUrl}/Companies`,
        editedClient,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setIsEditingReference(false);
        notification.success({
          message: 'Reference updated successfully'
        });
      } else {
        throw new Error('Failed to update reference');
      }
    } catch (error) {
      console.error('Error updating reference:', error);
      notification.error({
        message: 'Failed to update reference',
        description: error.response?.data?.errors?.[0] || 'An unexpected error occurred'
      });
    }
  };

  const renderField = (label, name, type = 'text', step, options = []) => {
    if (name === 'reference') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2 flex items-center">
            <input
              type="text"
              id={name}
              name={name}
              value={editedClient[name] || ''}
              onChange={handleInputChange}
              disabled={!isEditingReference}
              className={`w-full px-3 py-2 rounded-md shadow-sm ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white' 
                  : 'border-gray-300 text-gray-900'
              } mr-2`}
            />
            <button
              type="button"
              onClick={() => {
                if (isEditingReference) {
                  handleSubmitReference();
                } else {
                  setIsEditingReference(true);
                }
              }}
              className={`px-3 py-1 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
              }`}
            >
              {isEditingReference ? 'Save' : 'Edit'}
            </button>
          </div>
        </div>
      );
    }

    if (name === 'tel') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <PhoneInput
              country={'gb'}
              onlyCountries={['gb', 'ae']}
              value={editedClient[name]}
              onChange={(phone) => handleInputChange({ target: { name, value: phone } })}
              disabled={true}
              inputProps={{
                name: 'tel',
                id: 'tel',
                className: `w-full px-3 py-2 rounded-md shadow-sm ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white' 
                    : 'border-gray-300 text-gray-900'
                } pl-[60px]`,
              }}
              containerClass="w-full"
              buttonClass={`${isDarkMode ? 'bg-gray-700 border-gray-600' : ''} absolute top-0 left-0`}
              dropdownClass={`${isDarkMode ? 'bg-gray-700 text-white' : ''} !w-[300px]`}
            />
          </div>
        </div>
      );
    }


    return (
        <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            {type === 'checkbox' ? (
              <input
                type="checkbox"
                id={name}
                name={name}
                checked={editedClient[name] || false}
                onChange={handleInputChange}
                disabled={true}
                className={`form-checkbox h-5 w-5 ${
                  isDarkMode ? 'text-blue-600 bg-gray-700' : 'text-indigo-600'
                }`}
              />
            ) : type === 'select' ? (
              <select
                id={name}
                name={name}
                value={editedClient[name] || ''}
                onChange={handleInputChange}
                disabled={true}
                className={`w-full px-3 py-2 rounded-md shadow-sm ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white' 
                    : 'border-gray-300 text-gray-900'
                }`}
              >
                <option value="">Select {label}</option>
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={type}
                id={name}
                name={name}
                value={editedClient[name] || ''}
                onChange={handleInputChange}
                disabled={true}
                step={step}
                className={`w-full px-3 py-2 rounded-md shadow-sm ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white' 
                    : 'border-gray-300 text-gray-900'
                }`}
              />
            )}
          </div>
        </div>
      );
    };
  
    return (
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-2/3 lg:pr-6 mb-6 lg:mb-0">
          <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                Client Information
              </h3>
            </div>
            <div className="px-4 py-5 sm:p-6 space-y-4">
              {renderField('Reference', 'reference')}
              {renderField('Name', 'name')}
              {renderField('Address 1', 'address1')}
              {renderField('Town', 'town')}
              {renderField('County', 'county')}
              {renderField('Zip/Post Code', 'zippostCode')}
              {renderField('Telephone', 'tel')}
              {renderField('Country', 'countryCode', 'select', null, ['United Kingdom', 'United Arab Emirates'])}
              {renderField('Admin Registration Code', 'adminRegistrationCode')}
              {renderField('Minimum Daily Hours', 'minimumdailyhours', 'number', '0.01')}
              {renderField('Billing Active', 'billingactive', 'checkbox')}
              {renderField('Database Name', 'databaseName')}
              {renderField('State', 'state', 'number')}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 lg:pl-6">
          <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden mb-6">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
                Systems
              </h3>
              <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                {systems.map((system) => (
                  <li key={system.id} className="py-3 flex justify-between items-center">
                    <span className="text-sm text-gray-900 dark:text-white">{system.name}</span>
                    <button
                      onClick={() => navigate(`/client-admin/systems/${system.id}`)}
                      className={`px-2 py-1 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                        isDarkMode
                          ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                          : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
                      }`}
                    >
                      Edit
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
                Branches
              </h3>
              <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                {branches.map((branch) => (
                  <li key={branch.id} className="py-3 flex justify-between items-center">
                    <span className="text-sm text-gray-900 dark:text-white">{branch.name}</span>
                    <button
                      onClick={() => navigate(`/client-admin/branches/${branch.id}`)}
                      className={`px-2 py-1 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                        isDarkMode
                          ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                          : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
                      }`}
                    >
                      Edit
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
 
  DetailsTab.propTypes = {
  client: PropTypes.object.isRequired,
  systems: PropTypes.array.isRequired,
  branches: PropTypes.array.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};

  export default DetailsTab;