import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import Header from './Header';

const FullWidthLayout = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`flex flex-col h-screen font-sans ${isDarkMode ? 'dark' : ''}`}>
      <Header toggleSidebar={() => {}} />
      <main className="flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-800">
        <div className="p-4 h-full">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default FullWidthLayout;