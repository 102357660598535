import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types'; 

const TokenSetter = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserMetadata = async () => {
      if (isAuthenticated) {
        const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience,
              scope: 'read:current_user profile email offline_access',
            },
          });
          
          window.localStorage.setItem('app.Authorization', accessToken);
          // console.log('Token:', accessToken);
          // console.log('Audience:', audience);
          dispatch({
            type: 'user/LOGIN',
            payload: {},
          });
        } catch (e) {
          // console.error('Error fetching token:', e);
          // console.error('token' + e.error);
          localStorage.clear();
          window.location.href = process.env.REACT_APP_PORTAL_URL; 
        }
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, isAuthenticated, dispatch]);

  return children;
};

TokenSetter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TokenSetter;
