import React from 'react';
import PropTypes from 'prop-types';

const ClientHeader = ({ navigate, isDarkMode }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
      <h1 className="text-2xl font-bold mb-4 sm:mb-0">Client Details</h1>
      <button
        type="button"
        onClick={() => navigate('/client-admin/client')}
        className={`w-full sm:w-auto px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 
          ${isDarkMode 
            ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white' 
            : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
          }`}
      >
        Back
      </button>
    </div>
  );
};

ClientHeader.propTypes = {
   navigate: PropTypes.func.isRequired,
   isDarkMode: PropTypes.bool.isRequired
};

export default ClientHeader;