import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../contexts/ThemeContext'; 
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthenticationWrapper = ({ children }) => {
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [redirectAttempts, setRedirectAttempts] = useState(0);
  const { isDarkMode } = useTheme();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    
    if (token) {
      setTokenLoaded(true);
    } else if (!isAuthenticated && !isLoading && redirectAttempts < 3) {
      setRedirectAttempts((prev) => prev + 1);
      loginWithRedirect({
        appState: { returnTo: location.pathname + location.search },
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, redirectAttempts, location]);

  const spinnerStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    .spinner {
      border: 4px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 186, 255, 0.3)'};
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border-left-color: ${isDarkMode ? '#ffffff' : '#00baff'};
      animation: spin 1s linear infinite;
    }
  `;

  const LoadingSpinner = () => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: isDarkMode ? '#1F2937' : '#ffffff',
      color: isDarkMode ? '#ffffff' : '#333333',
    }}>
      <style>{spinnerStyle}</style>
      <div className="spinner"></div>
      <p>Authenticating... Please wait.</p>
    </div>
  );

  if (isLoading || (!isAuthenticated && !tokenLoaded)) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Authentication error: {error.message}</div>;
  }

  return children;
};

AuthenticationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticationWrapper;
