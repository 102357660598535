import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';
import { fetchClientDetails, fetchSystems, fetchBranches, fetchVaData, fetchHolidays, fetchMatchedConversations, fetchUnknownMessages, fetchAiProcessingData, fetchOutboxMessages } from '../../../utils/ApiFetch';
import ClientHeader from '../../../components/ClientAdminComponents/ClientHeader';
import ClientTabs from '../../../components/ClientAdminComponents/ClientTabs';
import DetailsTab from '../../../components/ClientAdminComponents/DetailsTab';
import LogsTab from '../../../components/ClientAdminComponents/LogTab';
import AiInfoTab from '../../../components/ClientAdminComponents/AiInfoTab';
import VaTab from '../../../components/ClientAdminComponents/VaTab';
import HolidaysTab from '../../../components/ClientAdminComponents/HolidaysTab';
import InboxTab from '../../../components/ClientAdminComponents/InboxTabs';
import OutboxTab from '../../../components/ClientAdminComponents/OutboxTab';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { grabToken } from '../../../utils/api';

const ClientDetailsPage = () => {
  const [client, setClient] = useState({});
  const [systems, setSystems] = useState([]);
  const [branches, setBranches] = useState([]);
  const [vaData, setVaData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [matchedConversations, setMatchedConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unknownMessages, setUnknownMessages] = useState([]);
  const [activeTab, setActiveTab] = useState('details');
  const [aiProcessingData, setAiProcessingData] = useState([]);
  const [outboundMessages, setOutboundMessages] = useState([]);
  
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

const fetchData = async () => {
  setLoading(true);
  try {
    const token = grabToken();
    const clientData = await fetchClientDetails(id, token);
    const outboxData = await fetchOutboxMessages(id, token);
    
    setClient(clientData);
    setSystems(await fetchSystems(id, token));
    setBranches(await fetchBranches(id, token));
    setVaData(await fetchVaData(id, token));
    setHolidays(await fetchHolidays(id, token));
    setMatchedConversations(await fetchMatchedConversations(id, token));
    setUnknownMessages(await fetchUnknownMessages(id, token));
    setAiProcessingData(await fetchAiProcessingData(id, token));
    setOutboundMessages(outboxData);
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};
  
  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500 font-bold text-center">{error}</div>;

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <ClientHeader navigate={navigate} isDarkMode={isDarkMode} />
        <ClientTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        
        <div>
          {activeTab === 'details' && <DetailsTab client={client} systems={systems} branches={branches} isDarkMode={isDarkMode} />}
          {activeTab === 'logs' && <LogsTab />}
          {activeTab === 'aiInfo' && <AiInfoTab client={client} setClient={setClient} isDarkMode={isDarkMode} />}
          {activeTab === 'va' && <VaTab vaData={vaData} isDarkMode={isDarkMode} navigate={navigate} />}
          {activeTab === 'holidays' && <HolidaysTab holidays={holidays} setHolidays={setHolidays} isDarkMode={isDarkMode} clientId={id} />}
          {activeTab === 'inbox' && <InboxTab matchedConversations={matchedConversations} unknownMessages={unknownMessages} fetchAiProcessingData={aiProcessingData} isDarkMode={isDarkMode} />}
          {activeTab === 'outbox' && <OutboxTab outboundMessages={outboundMessages} isDarkMode={isDarkMode} />}
        </div>
      </div>
    </div>
  );
};

export default ClientDetailsPage;