import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from './contexts/ThemeContext';
import LayoutWrapper from './components/Layouts/LayoutWrapper';
import AuthenticationWrapper from './components/AuthWrapper';
import LogoutCallback from './components/LogoutCallBack';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import DataPage from './pages/ClientAdmin/Client/ClientDataPage';
import SystemDataPage from './pages/ClientAdmin/Systems/SystemsDataPage';
import BranchesDataPage from './pages/ClientAdmin/Branch/BranchesDataPage';
import BranchDetailsPage from './pages/ClientAdmin/Branch/BranchDetailPage';
import AddBranchPage from './pages/ClientAdmin/Branch/AddBranchPage';
import SystemsDetailPage from './pages/ClientAdmin/Systems/SystemsDetailPage';
import ProductDataPage from './pages/InternalAdmin/Products/ProductDataPage';
import ProductDetailPage from './pages/InternalAdmin/Products/ProductDetailPage';
import AIMLDataPage from './pages/InternalAdmin/AI/AIModelDataPage';
import AIModelDetailPage from './pages/InternalAdmin/AI/AIModelDetailPage';
import ProviderDataPage from './pages/InternalAdmin/Providers/ProviderDataPage';
import ProviderDetailPage from './pages/InternalAdmin/Providers/ProviderDetailPage';
import VirtualAssistantsDataPage from './pages/ClientAdmin/VirtualAssistants/VirtualAssistantsDataPage';
import VirtualAssistantDetailPage from './pages/ClientAdmin/VirtualAssistants/VirtualAssistantDetailsPage';
import ClientDetailsPage1 from './pages/ClientAdmin/Client/ClientDetailsPage1';
import CustomerDetailPage from './pages/ClientAdmin/Customer/CustomerDetailPage';
import ConversationDetailPage from './pages/ClientAdmin/Conversation/ConversationDetailPage'; 
import AddSystemPage from './pages/ClientAdmin/Systems/AddSystemPage';
import CallbackPage from './pages/CallBack/CallBackPage';
import ErrorPage from './pages/Error/ErrorPage';
import ErrorBoundary from './pages/Error/ErrorBoundary';
import TokenSetter from './utils/tokenSetter';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from './store';

const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };


  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        // audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email'
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const App = () => {
  const [auth0Error] = useState(null);


  if (!process.env.REACT_APP_AUTH0_DOMAIN || !process.env.REACT_APP_AUTH0_CLIENT_ID) {
    console.error('Auth0 configuration is missing');
    return <div>Error: Auth0 configuration is missing. Please check your environment variables.</div>;
  }

  return (
    <Provider store={store}>
      <Router>
        <Auth0ProviderWithNavigate>
          <ThemeProvider>
            <AuthenticationWrapper>
              <TokenSetter>
                {auth0Error ? (
                  <div>Authentication Error: {auth0Error}</div>
                ) : (
                  <ErrorBoundary>
                    <Routes>
                      <Route path="/" element={<LayoutWrapper />}>
                        <Route index element={<Dashboard />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="client-admin">
                          <Route path="client" element={<DataPage />} />
                          <Route path="clients/:id" element={<ClientDetailsPage1 />} />
                          <Route path="systems" element={<SystemDataPage />} />
                          <Route path="systems/:id" element={<SystemsDetailPage />} />
                          <Route path="systems/add" element={<AddSystemPage />} />
                          <Route path="branches" element={<BranchesDataPage />} />
                          <Route path="branches/:id" element={<BranchDetailsPage />} />
                          <Route path="branches/add" element={<AddBranchPage />} />
                          <Route path="virtual-assistants" element={<VirtualAssistantsDataPage />} />
                          <Route path="virtual-assistants/:id" element={<VirtualAssistantDetailPage />} />
                          <Route path="customer/:contactId" element={<CustomerDetailPage />} />
                          <Route path="conversation/:id" element={<ConversationDetailPage />} />
                        </Route>
                        <Route path="internal-admin">
                          <Route path="products" element={<ProductDataPage />} />
                          <Route path="products/:id" element={<ProductDetailPage />} />
                          <Route path="AIML" element={<AIMLDataPage />} />
                          <Route path="aiml-models/:id" element={<AIModelDetailPage />} />
                          <Route path="providers" element={<ProviderDataPage />} />
                          <Route path="providers/:id" element={<ProviderDetailPage />} />
                        </Route>
                      </Route>
                      <Route path="error" element={<ErrorPage />} />
                      <Route path="/callback" element={<CallbackPage />} />
                      <Route path="/logout-callback" element={<LogoutCallback />} />
                    </Routes>
                  </ErrorBoundary>
                )}
              </TokenSetter>
            </AuthenticationWrapper>
          </ThemeProvider>
        </Auth0ProviderWithNavigate>
      </Router>
    </Provider>
  );
}

Auth0ProviderWithNavigate.propTypes = {
   children: PropTypes.node
};

export default App;