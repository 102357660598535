import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { grabToken } from '../../../utils/api';

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-full">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 dark:border-gray-100"></div>
  </div>
);

const DataPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    id: '',
    name: '',
    address1: '',
    town: '',
    tel: '',
    state: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [totalItems, setTotalItems] = useState(0); // Total items from the API response
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(currentPage); // Fetch data when page changes
  }, [currentPage]);

  const fetchData = async (page) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const token = grabToken(); 
      const response = await axios.get(`${apiBaseUrl}/Companies/filter`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: {
          page: page,  // Use current page
          pageSize: 50, // Adjust this to how many items you want per API call
        }
      });
      
      if (response.data && response.data.result && Array.isArray(response.data.result.list)) {
        setData(response.data.result.list); // Set the current page data
        setTotalItems(response.data.result.totalElementsCount); // Set total items count from API
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (property, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [property]: value
    }));
    setCurrentPage(1);
  };

  const filteredData = data.filter(item =>
    (item.id?.toLowerCase().includes(filters.id.toLowerCase()) ?? false) &&
    (item.name?.toLowerCase().includes(filters.name.toLowerCase()) ?? false) &&
    (item.address1?.toLowerCase().includes(filters.address1.toLowerCase()) ?? false) &&
    (item.town?.toLowerCase().includes(filters.town.toLowerCase()) ?? false) &&
    (item.tel?.toLowerCase().includes(filters.tel.toLowerCase()) ?? false) &&
    (item.state?.toString().includes(filters.state) ?? false)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowClick = (clientId) => {
    navigate(`/client-admin/clients/${clientId}`);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500 font-bold text-center">Error: {error}</div>;

  return (
    <div className="w-full h-full overflow-hidden">
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center mb-4 px-4">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Client Admin | Clients</h1>
        </div>
        <div className="flex-grow overflow-auto">
          <div className="bg-white dark:bg-gray-700 shadow overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  {['ID', 'Name', 'Address', 'Town', 'Tel', 'State'].map((header) => (
                    <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      {header}
                      <input
                        type="text"
                        placeholder={`Search ${header}`}
                        className="block w-full mt-1 p-2 border rounded text-sm dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600"
                        value={filters[header === 'Address' ? 'address1' : header.toLowerCase()]}
                        onChange={(e) => handleFilterChange(header === 'Address' ? 'address1' : header.toLowerCase(), e.target.value)}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-700 dark:divide-gray-600">
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                      No data available
                    </td>
                  </tr>
                ) : (
                  currentItems.map((item) => (
                    <tr 
                      key={item.id} 
                      onClick={() => handleRowClick(item.id)}
                      className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.address1}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.town}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{item.tel}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          item.state === 0 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {item.state === 0 ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        
        {/* Pagination */}
        <div className="bg-white dark:bg-gray-700 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-600 sm:px-6">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastItem >= totalItems}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700 dark:text-gray-300">
                Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to <span className="font-medium">{Math.min(indexOfLastItem, totalItems)}</span> of{' '}
                <span className="font-medium">{totalItems}</span> results
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => paginate(i + 1)}
                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                      currentPage === i + 1
                        ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                    }`}
                  >
                    {i + 1}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataPage;
