import LoadingSpinner from '../../../components/LoadingSpinner'; 
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { fetchCustomersData, fetchMessageContent } from '../../../utils/ApiFetch';
import { useTheme } from '../../../contexts/ThemeContext';
import CustomerInfoTable from '../../../components/ClientAdminComponents/CustomerInfoTable';
import PropTypes from 'prop-types';
import { Tag } from 'lucide-react';
import { grabToken } from '../../../utils/api';

const EmailContent = ({ email, isDarkMode }) => {
  if (!email) return null;

  const Tag = ({ label, value }) => (
    <span className={`inline-block px-2 py-1 rounded-full text-xs font-semibold mr-2 mb-2 ${
      isDarkMode ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-700'
    }`}>
      {label}: {value}
    </span>
  );

  function parseISO8601(isoString) {
    const date = new Date(isoString);
    
    if (isNaN(date.getTime())) {
      return null;
    }

    const results = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),

      formatted: date.toLocaleString(),

      dateObject: date
    };

    return results;
  }

  return (
    <div className={`bg-white dark:bg-white-700 rounded-lg shadow-lg overflow-hidden mt-6 ${isDarkMode ? 'text-black' : 'text-gray-800'}`}>
      <h2 className={`text-xl font-semibold p-4 ${isDarkMode ? 'bg-gray-600 text-white' : 'bg-gray-100'} border-b border-gray-200 dark:border-gray-600`}>
      <Tag label="Subject" value={email.subject || 'N/A'} /> 
      </h2>
      <div className="p-6">
      <div className="flex flex-wrap mb-4">
        <Tag label="From" value={email.senderEmail || 'System Generated'} />
        <Tag label="To" value={email.toRecipients?.join(', ') || 'N/A'} />
        <Tag label="CC" value={email.cc?.length > 0 ? email.cc.join(', ') : 'N/A'} />
        {email.bcc && email.bcc.length > 0 && <Tag label="BCC" value={email.bcc.join(', ')} />}
        <Tag 
          label="Created" 
          value={email.createdDate ? 
            (() => {
              const parsed = parseISO8601(email.createdDate);
              return `${parsed.day}/${parsed.month}/${parsed.year} ${parsed.hour}:${parsed.minute}:${parsed.second}`;
            })()
            : "N/A"
          } 
        />
        <Tag 
          label="Sent" 
          value={email.sentOnUtc ? 
            (() => {
              const parsed = parseISO8601(email.sentOnUtc);
              return `${parsed.day}/${parsed.month}/${parsed.year} ${parsed.hour}:${parsed.minute}:${parsed.second}`;
            })()
            : "N/A"
          } 
        />
        <Tag label="Created By" value={email.createdUser ? `${email.createdUser.firstName} ${email.createdUser.lastName}` : 'N/A'} />
        <Tag label="Updated By" value={email.updatedUser ? `${email.updatedUser.firstName} ${email.updatedUser.lastName}` : 'N/A'} />
      </div>
        <div className="mt-4">
          <h3 className="font-semibold mb-2">Content:</h3>
          <div 
            className="prose max-w-none dark:prose-invert"
            dangerouslySetInnerHTML={{ __html: email.content || 'No content available' }} 
          />
        </div>
      </div>
    </div>
  );
};

const CustomerDetailPage = () => {
  const [clientData, setClientData] = useState(null);
  const [emailMessages, setEmailMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { contactId } = useParams();
  const { isDarkMode } = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = grabToken();
        const data = await fetchCustomersData(contactId, token);
        let emailData = await fetchMessageContent(contactId, token);
        console.log('Email data:', emailData);
        console.log('data:', data); 
        if (location.state && location.state.filteredEmail) {
          emailData = [location.state.filteredEmail];
        }
        
        setClientData(data);
        setEmailMessages(emailData || []);
        
        console.log('Client data:', data);
        console.log('Email data:', emailData);
      } catch (err) {
        setError('Failed to fetch data');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    if (contactId) {
      fetchData();
    }
  }, [contactId, location.state]);


  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500 font-bold text-center">{error}</div>;
  if (!clientData) return <div className="text-center">No client data available</div>;

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/client-admin/client');
    }
  };

  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Customer Details</h1>
          <button
            type="button"
            onClick={handleBackClick}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back
          </button>
        </div>

        {clientData && (
          <CustomerInfoTable
            customer={clientData.customer}
            vehicle={clientData.vehicle}
            branch={clientData.branch}
            conversation={clientData.conversation}
            isDarkMode={isDarkMode}
          />
        )}

      <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">Email Messages</h2>
          <div className="space-y-6">
            {[...emailMessages]
              .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
              .map((email) => (
                <EmailContent key={email.id} email={email} isDarkMode={isDarkMode} />
            ))}
          </div>
        </div> 
      </div>
    </div>
  );
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};


EmailContent.propTypes = {
  email: PropTypes.object.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};



export default CustomerDetailPage;