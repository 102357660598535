import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../../contexts/ThemeContext';
import Sidebar from './Sidebar';
import logo from '../../assets/logo.png';

const DefaultLayout = () => {
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isDarkMode, toggleDarkMode } = useTheme();

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (!mobile) {
        setIsSidebarExpanded(false);
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogoClick = (e) => {
    e.preventDefault();
    window.location.href = '/';
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`flex flex-col h-screen font-sans ${isDarkMode ? 'dark' : ''}`}>
      {/* Top Bar */}
      <header className="bg-[#00baff] dark:bg-gray-800 text-white h-16 z-30 relative">
        <div className="h-full flex justify-between items-center">
          {/* Left side - Logo and Toggle */}
          <div className="flex items-center pl-6">
            <button className="p-1 hover:bg-blue-700 dark:hover:bg-gray-700 rounded" onClick={toggleSidebar}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
            <a href="/" onClick={handleLogoClick} className="flex items-center ml-2">
              <img src={logo} alt="Logo" className="h-8 w-auto" />
              <h1 className="text-xl font-bold hidden sm:inline ml-2">Vision2</h1>
            </a>
          </div>
          
          {/* Right side - Night Mode Toggle and User Menu */}
          <div className="flex items-center">
            <button
              onClick={toggleDarkMode}
              className="mr-6 p-2 rounded-full bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-white"
            >
              {isDarkMode ? '☀️' : '🌙'}
            </button>

            <div className="relative">
              <button 
                onClick={() => setIsUserMenuOpen(!isUserMenuOpen)} 
                className="flex items-center focus:outline-none"
              >
                {isAuthenticated ? (
                  <>
                    <span className="mr-2 text-sm hidden md:inline">{user.name}</span>
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </>
                ) : (
                  <span>Login</span>
                )}
              </button>
              {isUserMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-md overflow-hidden shadow-xl z-10">
                  {isAuthenticated ? (
                    <>
                      <div className="px-4 py-2 text-sm text-gray-700 dark:text-gray-200">
                        <div className="font-semibold">{user.name}</div>
                        <div className="text-xs text-gray-500 dark:text-gray-400">{user.email}</div>
                      </div>
                      <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600" onClick={() => setIsUserMenuOpen(false)}>
                        My Profile
                      </Link>
                      <button
                        onClick={() => {
                         window.location.href = 'https://portal.ai-assistant.io/'; 
                          setIsUserMenuOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                      >
                        Portal
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        loginWithRedirect();
                        setIsUserMenuOpen(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                    >
                      Login
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <div className="flex flex-1 overflow-hidden bg-gray-100 dark:bg-gray-800">
        <Sidebar isExpanded={isSidebarExpanded} isMobile={isMobile} />
        <main className={`
          flex-1 overflow-y-auto transition-all duration-300 ease-in-out
          ${isMobile ? 'w-full' : ''}
        `}
        style={{
          marginLeft: isMobile ? '0' : (isSidebarExpanded ? '256px' : '64px'),
          width: isMobile ? '100%' : (isSidebarExpanded ? 'calc(100% - 256px)' : 'calc(100% - 64px)')
        }}
        >
          <div className="p-4 h-full">
            <Outlet />
          </div>
        </main>
      </div>

      {isMobile && isSidebarExpanded && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
};

export default DefaultLayout;